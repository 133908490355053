import parse from "html-react-parser";
import React from "react";

function ChoiceFraudComponent(props) {

    return (
        <React.Fragment>
            <p className="eca-terminate-summary1">
            {parse(props.utilityLabel1.utilityLabel.terminateSummary4)}
            <a href={props.utilityLabel1.utilityLabel.hrefEnroll}
                className="underlined"
                id="enroll"
                onClick={(e) => {
                props?.trackEvents("FraudSorry", "Enroll", props.sessionID);
                }}
                title="Click this link to navigate Enroll page."
            >
                {props.utilityLabel1.utilityLabel.enrollNowThankyou2}
            </a>{" "}
            {props.utilityLabel1.utilityLabel.or}{" "}
            <a href={props.utilityLabel1.utilityLabel.hrefSignOn}
                className="underlined"
                id="signOn"
                onClick={(e) => {
                props?.trackEvents("FraudSorry", "SignOn", props.sessionID);
                }}
                title="Click this link to navigate SignOn page."
            >
                {props.utilityLabel1.utilityLabel.signOnSorry}{"."}
            </a>{" "}
            </p>
            <p className="eca-terminate-summary2">
          {parse(props.utilityLabel1.utilityLabel.terminateSummary2)}
        </p>
        </React.Fragment>
    );
}

export default ChoiceFraudComponent;
