import {utilityCommonLabel} from "./resources.common";
const utilityLibertyLabel={
    //Thankyou
      CConboardingpreferencesSVG: require("../../global/img/CC_onboarding_preferences.svg").default,
      accountSummarySmallSVG: require("../../global/img/first_time_experience-account_summary_small.svg").default,
      CConboardingpreferences: require("../../global/img/CC_onboarding_preferences.png"),
      accountSummarySmall: require("../../global/img/bilt_first_time_experience-account_summary_small.png"),
      thankyouHeader: 'Great news, your card <span style="white-space:nowrap;">is confirmed!</span>',
      thankyouPageMessage1: "Your card and all cards associated with your account ending in",
      thankyouPageMessage2: "are now ready for use.",
      thankyouConfirmAdviseMessage: '',
      signOn: "Sign on",
      signOnSorry: "sign on",
      or: "or ",
      enroll: "enroll",
      enrollNowThankyou: "Enroll now",
      enrollNow: "enroll now.",
      thankyouPageMessage3:'Download or open the Wells Fargo Mobile<sup>&reg;</sup> app to add your card to a digital wallet.',
      thankyouPageMessage4:
        ' to Wells Fargo Online<sup>&reg;</sup> to make card payments, view recent transactions, set up credit card alerts, <span style="white-space:nowrap;">and more.</span>',
        altEqualHouseLogo: "Equal Housing Lender logo",
      enrollNowThankyou2: "Enroll now",

      altCConBoardingImg: "New card benefits",
      titleCConBoardingImg: "New card benefits",
      titleEnroll: "Open new page to enroll to Wells Fargo Online Banking",
      titleSignOn: "Open new page to sign on to Wells Fargo Online Banking",
      altAcctSummaryImg: "Your card is confirmed!",
      titleAcctSummaryImg: "Your card is confirmed!",
      hrefSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=activateCard&LOB=CONS",
      hrefEnroll: "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard",
      hrefThankyouSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=accountSummary&LOB=CONS",
      //Session and cancel modal popup
      ecaErrorMessage1: '<p className="eca-error-msg1"> Your session has timed out. Please try to confirm your <span style="white-space:nowrap;">card again.</span></p>',
      continueBtn: "Continue",
      idleModalTitle: "Do you want to continue your session?",
      idleModalMsg1: "For security reasons, your session will time out at ",
      idleModalMsg2: " unless you choose to continue.",
      cancelTitle: "Cancel",
      cancelMsg1: "Are you sure you want to leave?",
      cancelMsg2: "If you cancel now, your card won't be confirmed.",
      cancelMsg3: "If you're a Wells Fargo Online",
      cancelMsg4: 'customer, you can confirm online anytime. ',
      cancelMsg5: "You can also confirm your card by calling the customer service number on the back of your card.",
      cancelMsg6: "For your security, please close your browser.",
      cancelMsgContent: "If you're a Wells Fargo Online<sup>&reg;</sup> customer, you can confirm online anytime. ",
      cancelExitBtn: "Cancel and Exit",
      goBackBtn: "Go Back",
      closeDialog: "Close dialog",
      // Liberty Temporary page
      temporaryPageTitle: "You're too early",
      temporaryPageMsg1:"Come back on [0] when you can start using your new Choice Privileges<sup>&reg;</sup> Mastercard<sup>&reg;</sup>.",
      temporaryPageMsg2:"Until then your current Choice Privileges Visa credit card is active through April 30th.",
      faqButton:"FAQs",
      //fraud.js
      terminateTitle: 'Unfortunately, your card <span style="white-space:nowrap;">isn’t confirmed</span>',
      terminateSummary1: 'Let\'s try to confirm your card through Wells Fargo Online<sup>&reg;</sup>. ',
      terminateSummary2: 'You can also confirm your card by calling the customer service number on the back of <span style="white-space:nowrap;">your card.</span>',
      terminateSummary3: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
      terminateSummary4: "If you're a Wells Fargo Online<sup>&reg;</sup> customer, you can confirm your card online anytime. ",
      //systemerror.js
      switchoffTitle: 'Confirm <span style="white-space:nowrap;">your card</span>',
      switchoffSummary1: 'If you\'d rather confirm your card over the phone, you can call us at ',
      switchoffSummary2: 'You can also confirm your card through Wells Fargo Online<sup>&reg;</sup>. ',
      switchoffSummary3: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
      switchoffPhoneTitle: '1-866-537-8424',
      switchoffPhoneHref: 'tel:18665378424',

      //customerservice.js
      customerServiceTitle: 'Your card is <span style="white-space:nowrap;">not confirmed</span>',
      customerServiceSummary1: 'If you need help confirming your card, you can give us a call at the customer service number located on the back of' +
      '<span style="white-space:nowrap;">your card.</span>',
      customerServiceSummary2: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
      //message for 3 failed attempt
      warningMsgforFailedAttempt: '<p className="eca-error-msg1">The details you\'ve provided don\'t match our records. Please <span style="white-space:nowrap;">'+
      'try again.</span></p>'
  };

export const utilityLabel={...utilityCommonLabel,...utilityLibertyLabel};
