import React from 'react';
import ButtonMainComponent from './button.maincomponent';

function ButtonComponent(props) {
    if (props.productType === "gpcc"||  props.productType === "rubicon" || props.productType === "onekey"  || props.productType === "onekeyplus" ) {
        return (
            <div>
                <ButtonMainComponent  {...props} />
            </div>
        );
    } else {
        return (<ButtonMainComponent {...props} />);
    }
}

export default ButtonComponent;
