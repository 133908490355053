import React, { Component } from "react";
import "../../../App.css";

class CASAHeader extends Component {
  constructor(props) {
    super(props);
  }

  handleError(e) {
    e.target.onerror = null;
    e.target.src =
      require("../../../global/img/masthead-bilt-wf-317x22.png");
  }

  render() {
    return (
        <banner className="header-wrapper">
          <a href="#eca-confirm-title" className="skip-to-main-content-link">Skip to main content</a>
          <div className="container-masthead bg-bilt border-0 d-none d-md-block">
            <div className="container">
              <div className="row">
                <div className="col-md-7 col-lg-5 col-xl-4">
                <span className="logo">
                  <img
                      id="imgLogoBilt"
                      src={require("../../../global/img/masthead-bilt-wf-1995x114.svg").default}
                      alt="BILT and Wells Fargo"
                      onError={this.handleError}
                      className="img-fluid"
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
          <div
              className="container-masthead-small d-none d-sm-block d-md-none">
            <div className="container-fluid bg-bilt">
              <div className="row">
                <div className="col-8">
                <span className="logo">
                  <img
                      id="imgBiltLogo"
                      src={require("../../../global/img/masthead-bilt-wf-1995x114.svg").default}
                      alt="BILT and Wells Fargo"
                      onError={this.handleError}
                      className="img-fluid"
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
          <div
              className="container-masthead-mobile d-xs-block d-sm-none">
            <div className="container-fluid bg-bilt">
              <div className="row">
                <div className="col-12 text-center">
                <span className="logo">
                  <img
                      id="imgBiltMobile"
                      src={require("../../../global/img/masthead-bilt-wf-1995x114.svg").default}
                      alt="BILT and Wells Fargo"
                      onError={this.handleError}
                      className="img-fluid"
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
        </banner>
    );
  }
}

export default CASAHeader;
