import React, { Component } from "react";
import "../../../App.css";
import "../../../liberty.css";

class LibertyHeader extends Component {
  constructor(props) {
    super(props);
  }

  handleError(e) {
    e.target.onerror = null;
    e.target.src =
      require("../../../global/img/wf/masthead-liberty-wf-421x60.png");
  }

  render() {
    return (
        <banner className="header-wrapper">
          <a href="#eca-confirm-title" className="skip-to-main-content-link">Skip to main content</a>
          <div className="liberty container-masthead border-bottom-1 bg-pale d-none d-sm-block">
            <div className="container">
              <div className="row">
                <div className="col-12 text-left">
                <span className="logo">
                  <img
                      id="imgLibertyLogo"
                      src={require("../../../global/img/wf/masthead-liberty-wf-5040x719.svg").default}
                      alt="Choice Privileges Rewards and Wells Fargo"
                      onError={this.handleError}
                      className="img-fluid"
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
          <div className="liberty container-masthead-mobile bg-pale border-bottom-1 d-xs-block d-sm-none">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 text-center">
                <span className="logo">
                  <img
                      id="imglibertymobile"
                      src={
                        require("../../../global/img/wf/masthead-liberty-wf-5040x719.svg")
                            .default
                      }
                      alt="Choice Privileges Rewards and Wells Fargo"
                      onError={this.handleError}
                      className="img-fluid"
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
        </banner>
    );
  }
}

export default LibertyHeader;
