
import React, { Component } from 'react';
import { ServiceApi } from '../../environment'
import * as utility from '../../utilities/utils'
import history from "../../history";
import ShortLandingPage from '../landing.component/short.landing.component'


class NuDataComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.GetNuData = this.GetNuData.bind(this);
    }
    componentDidMount() {
    }

    LoadSorryPage= (eventID) => {
        history.push({
            pathname: utility.getURLInfo(),
            detail: {
                language: utility.getLanguage(),
                type: "UNKNOWN",
                entryPoint: utility.getEntryPoint(),
                productType: utility.getPartner(),
                eventID: eventID
            },
        });
    }

    GetNuDataInfo =(jennyUrl,atadunUrl,sessionID,eventID)=> {
        var PlacementName = "EnhancedCardActivation"
        var PageNumber = 1;
        (function (w, d, s, u, q, js, fjs, nds) {
            nds = w.ndsapi || (w.ndsapi = {}); nds.config = {
                q: [], ready: function (cb) {
                    this.q.push(cb)
                }
            }; js = d.createElement(s);
            fjs = d.getElementsByTagName(s)[0];
            js.src = u; fjs.parentNode.insertBefore(js, fjs); js.onload = function () {
                nds.load(atadunUrl);
            }
        }(window, document, "script", jennyUrl));
        
        var nds = window.ndsapi;
        nds.config.ready(function () {
            nds.setSessionId(sessionID)
            // Set the NuDetect placement and placement page
            nds.setPlacement(PlacementName);
            nds.setPlacementPage(PageNumber);
        }.bind(this));
        setTimeout(this.isDataEmpty(sessionID,atadunUrl,jennyUrl), 500);
    }

    isDataEmpty=(sessionID,atadunUrl,jennyUrl)=>{
        if(!sessionID || !jennyUrl || !atadunUrl) {
            return true
        } 
        else {
            return false
        }
            
    }

    GetNuData = (sessionID, eventID) => {
        
        var jennyUrl = ServiceApi.NuData.JENNYURL;
        var atadunUrl = ServiceApi.NuData.ATADUNURL;
        var nds = window.ndsapi
        try{
            
           var isDataEmpty=this.isDataEmpty(sessionID,atadunUrl,jennyUrl);
            if(isDataEmpty) {
                this.LoadSorryPage(eventID)
            } else if(!nds){
                setTimeout(this.GetNuDataInfo(jennyUrl,atadunUrl,sessionID,eventID), 500);
                
            } else {
                this.LoadSorryPage(eventID)
            }
            
    }catch(error){
      ShortLandingPage.getNuDataLogs(eventID,sessionID,error)
    }

    }

    render() {
        return (<React.Fragment></React.Fragment>);
    }
}

export {NuDataComponent};