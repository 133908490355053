import React from "react";
import parse from 'html-react-parser';
import * as utility from "../../../utilities/utils";
import NonFusionFooter from "../controls/nonfusion.footer.js";
import FusionFooter from "../controls/fusion.footer.js";
import { ServiceApi } from "../../../environment";

function Footer(props) {
     let params = utility.getParams();
     let prodType = utility.getPartner();

     let utilityLabel1 =  utility.findUtilityLabelFromProductForFooter(prodType, utility.getQString());
     let footer13Title = utilityLabel1.utilityLabel.footer13Title
     let copyRight = "Copyright";
     let fusionStyle = false;

     if(prodType === 'gpcc' || prodType === 'attune' || prodType === 'rubicon' || prodType === "onekey" || prodType === "onekeyplus") {
          fusionStyle = true;
          footer13Title = utilityLabel1.utilityLabel.footer13TitleFusion;
          copyRight = "";
     }
     return (
          <footer className="footer-wrapper mt-auto bg-dgray">
               <div className="container-fluid container-mastfoot d-none d-lg-block">
                    <div className="container">
                         <div className="row no-gutters">
                              <div className="col-12">
                              { fusionStyle ? <FusionFooter prodType={prodType} /> : <NonFusionFooter prodType={prodType} /> }
                              </div>
                              <div className="col-12">
                                   <p className="mastfoot-copyright mt-3"><span lang="en">
                                        {copyRight}</span> &copy; {utilityLabel1.utilityLabel.footer11Title}
                                        <span className="currentyear">{utilityLabel1.utilityLabel.footer12Title}</span>
                                        {parse(footer13Title)}</p>
                              </div>
                         </div>
                    </div>
               </div>
               <div className="container-fluid container-mastfoot-mobile d-block d-lg-none" aria-hidden="true">
                    <div className="row no-gutters">
                         <div className="col-12">
                              <ul className="list-inline mastfoot-links">
                                   <li className="list-inline-item align-top">
                                   <a href={parse(utilityLabel1.utilityLabel.footer1Href)}
                                             title={parse(utilityLabel1.utilityLabel.footer1Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer1Title)}>
                                             {parse(utilityLabel1.utilityLabel.footer1Title)}</a>
                                   </li>
                                    <li className="list-inline-item align-top">
                                                   <a href={utility.getQString() === "en" ? ServiceApi.FOOTER_ENG_URL : ServiceApi.FOOTER_SPN_URL}
                                                               title={parse(utilityLabel1.utilityLabel.footer14Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer14Title)}>
                                                               {parse(utilityLabel1.utilityLabel.footer14Title)}</a>
                                                                      </li>

                                   <li className="list-inline-item align-top">
                                       <a  href={parse(utilityLabel1.utilityLabel.footer2Href)}
                                                   title={parse(utilityLabel1.utilityLabel.footer2Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer2Title)}>
                                                   {parse(utilityLabel1.utilityLabel.footer2Title)}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                         <a href={parse(utilityLabel1.utilityLabel.footer3Href)}
                                                    title={parse(utilityLabel1.utilityLabel.footer3Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer3Title)}>
                                                    {parse(utilityLabel1.utilityLabel.footer3Title)}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                        <a href={parse(utilityLabel1.utilityLabel.footer5Href)}
                                                   title={parse(utilityLabel1.utilityLabel.footer5Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer5Title)}>
                                                   {parse(utilityLabel1.utilityLabel.footer5Title)}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                       <a href={parse(utilityLabel1.utilityLabel.footer6Href)}
                                                   title={parse(utilityLabel1.utilityLabel.footer6Title)} tabIndex="-1"
                                                   aria-label={parse(utilityLabel1.utilityLabel.footer6Title)}>{parse(utilityLabel1.utilityLabel.footer6Title)}</a>

                                   </li>
 <li className="list-inline-item align-top">
          <a href={parse(utilityLabel1.utilityLabel.footer7Href)}
            title={parse(utilityLabel1.utilityLabel.footer7Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer7Title)}>
            {parse(utilityLabel1.utilityLabel.footer7Title)}</a>
        </li>

         {(props.prodType === "rubicon" || !fusionStyle) ? "" :<li className="list-inline-item align-top">
                  <a href={parse(utilityLabel1.utilityLabel.footer8Href)}
                    title={parse(utilityLabel1.utilityLabel.footer8Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer8Title)}>
                    {parse(utilityLabel1.utilityLabel.footer8Title)}</a>
                </li> }


                                   <li className="list-inline-item align-top">
                                       <a href={parse(utilityLabel1.utilityLabel.footer9Href)}
                                                   title={parse(utilityLabel1.utilityLabel.footer9Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer9Title)}>
                                                   {parse(utilityLabel1.utilityLabel.footer9Title)}</a>
                                   </li>
                                   <li className="list-inline-item align-top">
                                       <a href={parse(utilityLabel1.utilityLabel.footer10Href)} title={parse(utilityLabel1.utilityLabel.footer10Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer10Title)}>
                                                 {parse(utilityLabel1.utilityLabel.footer10Title)}</a>
                                   </li>
                              </ul>
                         </div>
                         <div className="col-12">
                              <p className="mastfoot-copyright">&copy;  {utilityLabel1.utilityLabel.footer11Title}
                                   <span className="currentyear"> {utilityLabel1.utilityLabel.footer12Title}</span>
                                   {parse(footer13Title)}</p>
                         </div>
                    </div>
               </div>
          </footer>
     );
}

export default Footer;
