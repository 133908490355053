import { v4 as uuidv4 } from "uuid";
import { isMobile } from "react-device-detect";
import cardConfirmRequest from "../components/shared/models/card-confirmation-req.json";
import moment from "moment";
import { ServiceApi } from "../environment";
import * as dataService from "../services/data.service";

let deviceIP = "0.0.0.0";
let xForwardedFor = "0.0.0.0";

export const getUniqueID = () => {
  return uuidv4();
};

export const getParams = () => {
  const queryString = require("query-string");
  let params = queryString.parse(location.search);
  return params;
};
export const getTitleConfirmationSuccess = () => {
  let utilityLabels =getParams().language === "es" ? require("../components/resources/resources.es") : require("../components/resources/resources.en");
  document.title=utilityLabels.utilityLabel.titleNameSucess;
  return document.title;
  
};
export const getTitleConfirmationError = () => {
  let utilityLabels =getParams().language === "es" ? require("../components/resources/resources.es") : require("../components/resources/resources.en");
   document.title=utilityLabels.utilityLabel.titleNameError;
   return document.title;

};
export const getTitleSystemUnavailable = () => {
   let utilityLabels =getParams().language === "es" ? require("../components/resources/resources.es") : require("../components/resources/resources.en");
   document.title=utilityLabels.utilityLabel.titleNameSorry;
   return document.title;
 };
export const getTitleSessiontimeout = () => {
  let titleNameSessiontimeout =  "Confirm Card Details | Enhanced Card Activation  | Wells Fargo";
   document.title = titleNameSessiontimeout;
  return document.title;
 };
 export const getOLBTitleConfirmcard = () => {
  let utilityLabels = getParams().language === "es" ? require("../components/resources/resources.es") : require("../components/resources/resources.en");
  document.title=utilityLabels.utilityLabel.titleNameOLBConfirmcard;
  return document.title;
 };
 export const getTitleConfirmcard = () => {
  let utilityLabels = getParams().language === "es" ? require("../components/resources/resources.es") : require("../components/resources/resources.en");
  document.title=utilityLabels.utilityLabel.titleNameConfirmcard;
  return document.title;
 };
export const getLanguage = () => {
  let params = getParams();
  switch (params.language) {
    case "en":
      return "english";
    case "es":
      return "spanish";
    default:
      return "english";
  }
};

export const getPartner = () => {
    let params = getParams();
   if (params.partner !== null && params.partner !== undefined) {

       if(params.partner?.toLowerCase() ==="choice" && params.type?.toLowerCase() === "conversion"){
         return "ChoiceConv" ;
       }else if ( params.partner?.toLowerCase()==="bilt" || params.partner?.toLowerCase()==="choice" || params.partner?.toLowerCase()==="attune" || params.partner?.toLowerCase()==="onekey" || params.partner?.toLowerCase()==="onekeyplus"){
         return params.partner?.toLowerCase();
       }else {
            return "gpcc";
       }

    }else if(params.lob !== null && params.lob !== undefined && params.lob?.toLowerCase() === "business-cards") {
     return "rubicon";
    }else{
      return "gpcc";
    }
  };

export const getUserAction = () => {
  let params = getParams();
  if (params.useraction !== undefined) {
    return params.useraction;
  } else {
    return "";
  }
};

export const getQString = () => {
  let params = getParams();
  if (params.language !== undefined && params.language !== null) {
    return params.language;
  } else {
    return "en";
  }
};

export const getEntryType = () => {
  let params = getParams();
  if (params.entrypoint !== undefined) {
    return params.entrypoint.toUpperCase();
  } else if (params.entryPoint !== undefined) {
    return params.entryPoint.toUpperCase();
  } else {
    return "URL";
  }
};

export const getEntryPoint = () => {
  let params = getParams();
  if (params.entrypoint !== undefined) {
    return params.entrypoint.toUpperCase();
  } else if (params.entryPoint !== undefined) {
    return params.entryPoint.toUpperCase();
  } else {
    return "";
  }
};

export const getFlowInd = () => {
  let params = getParams();
  if (
    params.entrypoint !== undefined &&
    params.entrypoint.toUpperCase() === "QR"
  ) {
    return "Q";
  } else if (
    params.entryPoint !== undefined &&
    params.entryPoint.toUpperCase() === "QR"
  ) {
    return "Q";
  } else {
    return "U";
  }
};

export const getDeviceType = () => {
  if (isMobile) {
    return "mobile";
  } else {
    return "desktop";
  }
};

export const getDeviceIP = () => {
 deviceIP  = ServiceApi.PUBLICIP;
 if(deviceIP !== undefined){
  if (deviceIP.indexOf(",") !== -1) {
    deviceIP = deviceIP.substring(0, deviceIP.lastIndexOf(","));
  } else if (deviceIP.indexOf(":") !== -1) {
    deviceIP = deviceIP.substring(0, deviceIP.lastIndexOf(":"));
  }
  }
  return deviceIP;
};

export const getxForwardedFor = () => {
  xForwardedFor = ServiceApi.XFORWARDEDFOR;
  return xForwardedFor;
};

export const getSwitchHeader = (eventID, partner,sessionID) => {
  const headers = {
    "Content-Type": "application/json",
    "WF-deliveryChannelType": "ECA",
    "WF-senderHostName": window.location.hostname,
    "WF-creationTimeStamp": moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') ,
    "WF-senderSubApplicationID": "ECA",
    "WF-senderMessageId": eventID,
    "X-APPCERT-MANUAL": "IGTWY",
    "Cache-Control": "no-cache",
    "WF-senderApplicationId": "ECA",
    "Partner" : partner,
    "SessionID" : sessionID,
    "DeviceIP" : getDeviceIP(),
    "DeviceType" : getDeviceType()
  };
  return headers;
};

export const getCardConfirmationHeader = (eventID,sessionID) => {
  const headers = {
    "Content-Type": "application/json",
    //"WF-deliveryChannelType": "ECA",
    "WF-senderHostName": window.location.hostname,
    "WF-creationTimeStamp": moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') ,
    "WF-senderSubApplicationID": "ECA",
    "WF-senderMessageId": eventID,
    "X-APPCERT-MANUAL": "IGTWY",
    "Cache-Control": "no-cache",
    "WF-senderApplicationId": "ECA",
    "SessionID" : sessionID,
    "DeviceIP" : getDeviceIP(),
    "DeviceType" : getDeviceType()
  };
  return headers;
};

export const getCardConfirmationRequest = (
  formData,
  nuData,
  sessionID,
  eventID,
  productType
) => {


  const req = cardConfirmRequest[0];
  req.confirmCardRequest.language = getLanguage();
  req.confirmCardRequest.flowInd = getFlowInd();
  req.confirmCardRequest.partner = productType
  //Device Information
  req.confirmCardRequest.deviceInfo.deviceIp = getDeviceIP();
  req.confirmCardRequest.deviceInfo.deviceType = getDeviceType();
  //Browser Information
  req.confirmCardRequest.browserInfo.sessionId = sessionID;
  req.confirmCardRequest.browserInfo.sourceURL = window.location.href;
  req.confirmCardRequest.browserInfo.userAgent = navigator.userAgent;
  req.confirmCardRequest.browserInfo.xForwardedFor = getxForwardedFor();
  //EventData Information
  req.confirmCardRequest.eventData.eventId = eventID;
  req.confirmCardRequest.eventData.eventName = "CustomerEvent";
  req.confirmCardRequest.eventData.eventTSP =  moment().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
  //FormData Information
  req.confirmCardRequest.formData.pan = formData.ccNumber.replace(/ /g, "");
  req.confirmCardRequest.formData.cvv2 = formData.cvv;
  req.confirmCardRequest.formData.dob = formData.dob;
  req.confirmCardRequest.formData.last4SSN = formData.ssn;
  req.confirmCardRequest.formData.widgetData = nuData;
  req.confirmCardRequest.formData.pageNumber = "1";

  return req;
};



export const getTrackingRequestPayload = (page, event, eventID, sessionID) => {
  const payload = {
    trackingEvent: {
      channel: "ECA",
      page: page,
      action: event,
      language:getLanguage(),
      deviceIp: getDeviceIP(),
      deviceType: getDeviceType(),
      sessionId: sessionID,
      eventId: eventID,
      eventTSP: moment.utc().format(),
      productType:'000',
      productName: getPartner().toUpperCase()
    },
  };
  return payload;
};

export const getTrackingRequestHeaders = (eventID,sessionID) => {
  const headers = {
    "Content-Type": "application/json",
    "WF-deliveryChannelType": "ECA",
    "WF-senderHostName": window.location.hostname,
    "WF-creationTimeStamp": moment.utc().format(),
    "WF-senderSubApplicationID": "ECA",
    "WF-senderMessageId": eventID,
    "X-APPCERT-MANUAL": "IGTWY",
    "Cache-Control": "no-cache",
    "WF-senderApplicationId": "ECA",
    "SessionID" : sessionID
  };
  return headers;
};

export const trackEvent = (page, event, eventID, sessionID) => {
  dataService
    .trackEvent(page, event, eventID, sessionID)
    .then((envRes) => {})
    .catch((err) => {
      getUnknownSorry();
    });
};

export const getURLInfo = () => {
  let params = getParams()
  var path = ''
  if(params.language && params.partner){
    path =  `/?language=${params.language}&page=sorry&partner=${params.partner}`
  }
  else if(params.entryPoint && params.partner){
    path =  `/?entrypoint=${params.entryPoint}&page=sorry&partner=${params.partner}`
  }
  else if(params.language && params.entryPoint && params.partner){
    path =  `/?language=${params.language}&entrypoint=${params.entryPoint}&page=sorry&partner=${params.partner}`
  }
  else if(params.language && params.lob){
    path =  `/?language=${params.language}&page=sorry&lob=${params.lob}`
  }
  else if(params.entryPoint && params.lob){
    path =  `/?entrypoint=${params.entryPoint}&page=sorry&lob=${params.lob}`
  }
  else if(params.language && params.entryPoint && params.lob){
    path =  `/?language=${params.language}&entrypoint=${params.entryPoint}&page=sorry&lob=${params.lob}`
  } 
  else if (params.partner) {
    path =  `/?page=sorry&partner=${params.partner}`
  }
  else if (params.lob) {
    path =  `/?page=sorry&lob=${params.lob}`
  }
  else{
    path =  `/?page=sorry&partner=gpcc`
  }
 return path;
};

export const getNuDataLogsHeader = (eventID,sessionID) => {
  const headers = {
    "Content-Type": "application/json",
    "WF-deliveryChannelType": "ECA",
    "WF-senderHostName": window.location.hostname,
    "WF-creationTimeStamp": moment.utc().format(),
    "WF-senderSubApplicationID": "ECA",
    "WF-senderMessageId": eventID,
    "X-APPCERT-MANUAL": "IGTWY",
    "Cache-Control": "no-cache",
    "WF-senderApplicationId": "ECA",
    "SessionID" : sessionID
  };
  return headers;
};

export const getNudataLogsRequestPayload = (eventID, sessionID, error) => {
  let params = getParams();
  const payload = {
    nuDataLogs: {
      channel: "ECA",
      error:error,
      language:getLanguage(),
      deviceIp: getDeviceIP(),
      deviceType: getDeviceType(),
      sessionId: sessionID,
      eventId: eventID,
      eventTSP: moment.utc().format(),
      productType:'000',
      productName: params.partner !== 'bilt' ? 'GPCC' : 'BILT'
    },
  };
  return payload;
};

export const findUtilityLabelFromProduct = (productType, language) => {
  let utilityLabel1 = "";
  if (productType === "bilt") {
    utilityLabel1 = require("../components/resources/resources.casa");
  } else if (productType === "choice") {
    utilityLabel1 = require("../components/resources/resources.liberty");
  }  else if(productType === "rubicon") {
    utilityLabel1 = require("../components/resources/resources.rubicon");
  } else if (language === "es") {
    utilityLabel1 = require("../components/resources/resources.es");
  } else {
    utilityLabel1 = require("../components/resources/resources.en");
  }
  return utilityLabel1;
}

export const findUtilityLabelFromProductForFooter = (partner, language) => {

     let utilityLabel1 ="";
     if (partner === "bilt" || partner === "choice" ) {
       utilityLabel1 = require("../components/resources/resources.casa");
     }else if (partner === "rubicon") {
          utilityLabel1 = require("../components/resources/resources.rubicon");
     }else if (language === "es") {
       utilityLabel1 = require("../components/resources/resources.es");
     }else{
          utilityLabel1 = require("../components/resources/resources.en");
     }
     return utilityLabel1;
   }

export const trackEventsForRedirect = (page, event, sessionID, url) => {
  if (url !== null && url !== undefined) {
    window.location.replace(url);
  }
  if(sessionID !== null && sessionID !== "" && event !==null && event !== "") {
    dataService.trackEvent( page, event,  sessionID).then();
  }
};

export const trackEventsForNoredirect = (page, event, sessionID) => {
  if(sessionID !== null && sessionID !== "" && event !==null && event !== "") {
    dataService.trackEvent( page, event,  sessionID).then();
  }
};
