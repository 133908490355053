export const utilityLabel = {
  //hammer
  hammerSorryTitle: "Unfortunately, we were unable to confirm your card.",
  hammerSorrySummary: "You can confirm your card by calling the customer service number on the back of your card.",
  //short.landing.js
  lblConfirm: 'Confirm <span style="white-space:nowrap;">your card</span>',
  lblFusionConfirm: 'Confirm <span style="white-space:nowrap;">card details</span>',
  lblConfirmContent: 'We need a little bit of information to confirm that you have received <span style="white-space:nowrap;">your card.</span>',
  lblFusionConfirmContent: '',
  lblFieldRequired: "All fields are required.",
  //card number
  inputLabelCC: "Credit card number",
  lblCCMsgContent: 'Enter the full account number on <span style="white-space:nowrap;">your card.</span>',
  lblCCMsgFusionContent: '',
  errValidationCCNumber: 'Please enter a 15- or 16-digit credit <span style="white-space:nowrap;">card number.</span>',
  showCardNumber: "Show Card Number",
  hideCardNumber: "Hide Card Number",
  //amex nonamex img path
  ccAmexImgPath: require("../../global/img/card-cvv-amex.png"),
  ccNonAmexImgPath: require("../../global/img/card-cvv-nonamex.png"),
  ccBiltImgPath: require("../../global/img/card-cvc-mc.png"),
  imgAmexCaption: "Security code example",
  imgNonAMexCaption: "CVV example",
  //cvv
  inputLabelCVVAmex: "Credit card security code",
  inputLabelCVVNonAmex1: "Security code",
  inputLabelCVVNonAmex: "What’s a security code?",
  securityCodeDesc: "Your Security code is a 3 or 4 digit number specific to your card.<br /><br /><strong>Where is it located?</strong><br />",
  securityCodeDesc1: "Mastercard and Visa: 3 digit number on the back of your card, near the middle.<br />",
  securityCodeDesc2: "American Express: 4 digit number on the front of your card, towards the right.",
  securityCodeDescTitle: "What’s this?",
  imgClose: require("../../global/img/icon-close.png"),
  lblCVVAmexMsgContent: '',
  //lblCVVAmexMsgContent: 'Enter the 4-digit security code on the front of <span style="white-space:nowrap;">your card.</span>',
  lblCVVNonAmexMsgContent:'',
  //lblCVVNonAmexMsgContent: 'Enter the 3-digit CVV on the back of your card to the right of the <span style="white-space:nowrap;">signature line.</span>',
  errValidationCVVAmex: 'Please enter a 4-digit <span style="white-space:nowrap;">security code.</span>',
  errValidationCVVNonAmex: 'Please enter a <span style="white-space:nowrap;">3-digit security code.</span>',
  //dob
  inputLabelDOB: "Date of birth (mm/dd/yyyy)",
  lblDOBMsgContent: 'Enter the account holder\'s date <span style="white-space:nowrap;">of birth.</span>',
  lblDOBMsgFusionContent: '',
  errValidationDOB: 'Please enter the account holder’s date of birth formatted as 2-digit month/2-digit day/4-digit year.',

  //ssn
  inputLabelSSN: "SSN or ITIN (last 4 digits)",
  lblSSNMsgContent: 'Enter the last 4 digits of the account holder’s Social Security number or Individual Taxpayer <span style="white-space:nowrap;">Identification number.</span>',
  errValidationSSN: 'Please enter <span style="white-space:nowrap;">4-digits.</span>',

  btnCancel: "Cancel",
  btnConfirm: "Confirm",
  cardTypeAemx: "AMEX",
  dobFormat: "##/##/####",
  cardTypeNonAMex: "NONAMEX",
  idCCName: "cardNumber",
  idCVVName: "cvv",
  idSSNName: "ssn",

  sorryHeader: "We're sorry",
  buttonClose: "close",
  //Thankyou
  CConboardingpreferencesSVG: require("../../global/img/CC_onboarding_preferences.svg").default,
  accountSummarySmallSVG: require("../../global/img/first_time_experience-account_summary_small.svg").default,
  CConboardingpreferences: require("../../global/img/CC_onboarding_preferences.png"),
  accountSummarySmall: require("../../global/img/first_time_experience-account_summary_small.png"),
  thankyouHeader: 'Great news, your card <span style="white-space:nowrap;">is confirmed!</span>',
  thankyouPageMessage1: "Your card and all cards associated with your account ending in",
  thankyouPageMessage2: "are now ready for use.",
  thankyouConfirmAdviseMessage: 'Please remove the sticker from the front of your card and sign <span style="white-space:nowrap;">the back.</span>',
  signOn: "Sign on",
  enrollNowBtn: "Enroll now",
  signOnSorry: "sign on",
  or: "or ",
  enroll: "enroll",
  enrollNowThankyou: "enroll now",
  enrollNow: "enroll now.",
  thankyouPageMessage3: ' in online banking to add your card to a digital wallet, explore your card benefits, <span style="white-space:nowrap;">and more.</span>',
  altEqualHouseLogo: "Equal Housing Lender logo",
  altCConBoardingImg: "New card benefits",
  titleCConBoardingImg: "New card benefits",
  titleEnroll: "Open new page to enroll to Wells Fargo Online Banking",
  titleSignOn: "Open new page to sign on to Wells Fargo Online Banking",
  altAcctSummaryImg: "Your card is confirmed!",
  titleAcctSummaryImg: "Your card is confirmed!",
  hrefSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=activateCard&LOB=CONS",
  hrefEnroll: "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard",
  hrefThankyouSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=accountSummary&LOB=CONS&langPref=ENG",
  hrefEnrollNowBtnDone:"https://www.wellsfargo.com/online-banking/manage-accounts/",
  thankyouPage2Message1: "You’re good to go!",
  thankyouPage2Message2: "Your card is confirmed.",
  thankyouPage2Message3: "Start shopping, add card to a digital wallet, or sign on to continue exploring your card’s features.",
  thankyouPage2Message4 : " If you don’t have online banking yet, use the button below to enroll.",
  enrollOnlinebankingBtn: "Enroll in online banking",
  signOnGoToAccountsBtn: "Sign on go to accounts",
  thankyouPage2Message5: "Your card is ready to use!",

  //Session and cancel modal popup
  ecaErrorMessage1: '<p className="eca-error-msg1"> Your session has timed out. Please try to confirm your <span style="white-space:nowrap;">card again.</span></p>',
  continueBtn: "Continue",
  idleModalTitle: "Do you want to continue your session?",
  idleModalMsg1: "For security reasons, your session will time out at ",
  idleModalMsg2: " unless you choose to continue.",
  cancelTitle: "Cancel",
  cancelMsg1: "Are you sure you want to leave?",
  cancelMsg2: "If you cancel now, your card will not be confirmed.",
  cancelMsg3: "If you're a Wells Fargo Online",
  cancelMsg4: 'customer, you can confirm online anytime. ',
  cancelMsg5: "You can also confirm your card by calling the customer service number on the back of your card.",
  cancelMsg6: "For your security, please close your browser.",
  cancelMsgContent: "If you're a Wells Fargo Online<sup>&reg;</sup> customer, you can confirm online anytime. ",
  cancelExitBtn: "Cancel and Exit",
  goBackBtn: "Go Back",
  closeDialog: "Close dialog",
  //fraud.js
  terminateTitle: 'Unfortunately, your card is <span style="white-space:nowrap;">not confirmed</span>',
  terminateTitleFusion: 'Unfortunately, we were unable to confirm your card',
  terminateSummary1: 'Let\'s try to confirm your card through Wells Fargo Online<sup>&reg;</sup>. ',
  terminateSummary2: 'You can also confirm your card by calling the customer service number on the back of <span style="white-space:nowrap;">your card.</span>',
  terminateSummary4: 'You can confirm your card by calling the customer service number on the back of <span style="white-space:nowrap;">your card.</span>',
  terminateSummary3: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  terminateSummary5: 'You can confirm your card by calling the customer service number on the back of your card.',
  //systemerror.js
  switchoffTitle: 'Confirm <span style="white-space:nowrap;">your card</span>',
  switchoffSummary1: 'You can confirm your card through Wells Fargo Online<sup>&reg;</sup>. ',
  switchoffSummary2: 'If you\'d rather confirm your card over the phone, you can call us at ',
  switchoffSummary3: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  switchoffPhoneTitle: '1-866-537-8424',
  switchoffPhoneHref: 'tel:18665378424',
  switchoffSummary4: 'We’re unable to confirm your card at this time.',
  switchoffSummary5: 'To confirm your card over the phone, you can call us at ',
  switchoffSummary6: "We’re working on a fix",
  switchoffSummary7: "We can’t confirm your card online right now, but we can help you over the phone if you call ",

  //customerservice.js
  customerServiceTitle: 'Your card is <span style="white-space:nowrap;">not confirmed</span>',
  customerServiceSummary1: 'If you need help confirming your card, you can give us a call at the customer service number located on the back of' +
    '<span style="white-space:nowrap;">your card.</span>',
  customerServiceSummary2: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  //message for 3 failed attempt
  warningMsgforFailedAttempt: '<p className="eca-error-msg1">The details you provided do not match our records. Please <span style="white-space:nowrap;">try again.</span></p>',

  //olb confirm Card Landing
  lblOlbConfirm:'Let\'s confirm your <span style="white-space:nowrap;">new card!</span>',
  lblOlbConfirmLandingMsg1:'Sign on to confirm in 2 easy steps.',
  lblOlbConfirmLandingMsg2:'No online account?',
  signOnConfirm: "Sign On",
  confirmWithFewDetails: "Confirm with a few details.",
  hrefSignOnConfirm: "https://connect.secure.wellsfargo.com/auth/login/ulink?origin=cob&serviceType=activateCard",
  lblconfirmFewDetails: 'Confirm with a few details.',

  //Attune confirm Card Landing
  lblAttuneConfirm: 'Let\'s confirm your <span style="white-space:nowrap;">new card !</span>',
  signOntoConfirm: "Sign on to confirm",
  noOnlineAccount: "No online account?",
  enrolltoConfirm: "Enroll to confirm",
  hrefSignOnAttuneConfirm: "https://connect.secure.wellsfargo.com/auth/login/ulink?origin=cob&serviceType=activateCard",
  hrefEnrollOntoAttuneConfirm: "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard",
  //footer
  footer1Title: 'Privacy, Cookies, Security &amp; Legal',
  footer2Title: 'Notice of Data Collection',
  footer3Title: 'General Terms of Use',
  footer4Title: 'Ad Choices',
  footer5Title: 'Report Fraud',
  footer6Title: 'Sitemap',
  footer7Title: 'About Wells Fargo',
  footer8Title: 'Careers',
  footer9Title: 'Diversity and Accessibility',
  footer10Title: 'Home',
  footer1Href: 'https://www.wellsfargo.com/privacy-security/',
  footer2Href: 'https://www.wellsfargo.com/privacy-security/notice-of-data-collection/',
  footer3Href: 'https://www.wellsfargo.com/privacy-security/terms/',
  footer4Href: 'https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices',
  footer5Href: 'https://www.wellsfargo.com/privacy-security/fraud/report/',
  footer6Href: 'https://www.wellsfargo.com/sitemap',
  footer7Href: 'https://www.wellsfargo.com/about/?linkLoc=footer',
  footer8Href: 'https://www.wellsfargo.com/about/careers/',
  footer9Href: 'https://www.wellsfargo.com/about/diversity/',
  footer10Href: 'https://www.wellsfargo.com/',
  footerContentFusion: ' <ul className="mastfoot-ada-links">  <li>       ' +
  '<a href="https://www.wellsfargo.com/privacy-security/" title="Privacy, Cookies, Security and Legal"  tabIndex="-1" aria-label="Privacy, Cookies, Security and Legal">Privacy, Cookies, Security &amp; Legal</a>  </li>  ' +
  '  <li >       <a href="https://www.wellsfargo.com/privacy-security/notice-of-data-collection/" ' +
  'title="Notice of Data Collection" tabIndex="-1" aria-label="Notice of Data Collection">Notice of Data Collection</a>    <li>       ' +
  '<a href="https://www.wellsfargo.com/privacy-security/terms/" title="General Terms of Use"  tabIndex="-1" aria-label="General Terms of Use">General Terms of Use</a>  </li>  ' +
  '<li>       <a href="https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices" title="Ad Choices" tabIndex="-1" aria-label="Ad Choices">Ad Choices</a>  </li>  ' +
  ' <li>       ' +
  '<a href="https://www.wellsfargo.com/privacy-security/fraud/report/" title="Report Fraud" tabIndex="-1" aria-label="Report Fraud">Report Fraud</a>  </li>  ' +
  '  <li>       <a href="https://www.wellsfargo.com/sitemap" title="Sitemap" tabIndex="-1" aria-label="Sitemap">Sitemap</a>  </li>  ' +
  ' <li>      ' +
  '<a href="https://www.wellsfargo.com/about/?linkLoc=footer" title="About Wells Fargo" tabIndex="-1" aria-label="About Wells Fargo">About Wells Fargo</a></li></ul><ul className="mastfoot-ada-links">  ' +
  '<li>       ' +
  '<a href="https://www.wellsfargo.com/about/careers/" title="Careers" tabIndex="-1" aria-label="Careers">Careers</a></li><li>' +
  '<a href="https://www.wellsfargo.com/about/diversity/" title="Diversity and Accessibility" tabIndex="-1" aria-label="Diversity and Accessibility">Diversity and Accessibility</a></li>' +
  '<li ><a href="https://www.wellsfargo.com/" title="Home" tabIndex="-1" aria-label="Home">Home</a></li></ul>',
  footerContent: ' <ul className="list-inline mastfoot-links mb-0">  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/" title="Privacy, Cookies, Security and Legal" tabIndex="-1" aria-label="Privacy, Cookies, Security and Legal">Privacy, Cookies, Security &amp; Legal</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       <a href="https://www.wellsfargo.com/privacy-security/notice-of-data-collection/" ' +
    'title="Notice of Data Collection" tabIndex="-1" aria-label="Notice of Data Collection">Notice of Data Collection</a>  </li>  <li className="list-inline-item divider">|</li>  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/terms/" title="General Terms of Use" tabIndex="-1" aria-label="General Terms of Use">General Terms of Use</a>  </li>  <li className="list-inline-item divider">|</li>  ' +
    '<li className="list-inline-item">       <a href="https://www.wellsfargo.com/privacy-security/privacy/online/#adchoices" title="Ad Choices" tabIndex="-1" aria-label="Ad Choices">Ad Choices</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/privacy-security/fraud/report/" title="Report Fraud" tabIndex="-1" aria-label="Report Fraud">Report Fraud</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       <a href="https://www.wellsfargo.com/sitemap" title="Sitemap" tabIndex="-1" aria-label="Sitemap">Sitemap</a>  </li>  ' +
    '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">      ' +
    '<a href="https://www.wellsfargo.com/about/?linkLoc=footer" title="About Wells Fargo" tabIndex="-1" aria-label="About Wells Fargo">About Wells Fargo</a></li></ul><ul className="list-inline mastfoot-links mb-0">  ' +
    '<li className="list-inline-item">       ' +
    '<a href="https://www.wellsfargo.com/about/careers/" title="Careers" tabIndex="-1" aria-label="Careers">Careers</a></li><li className="list-inline-item divider">|</li><li className="list-inline-item">' +
    '<a href="https://www.wellsfargo.com/about/diversity/" title="Diversity and Accessibility" tabIndex="-1" aria-label="Diversity and Accessibility">Diversity and Accessibility</a></li>' +
    '<li className="list-inline-item divider">|</li><li className="list-inline-item"><a href="https://www.wellsfargo.com/" title="Home" tabIndex="-1" aria-label="Home">Home</a></li></ul>',
  footer11Title: '1999-',
  footer12Title: new Date().getFullYear(),
  footer13Title: ' Wells Fargo. All rights reserved. NMLSR ID 399801',
  footer13TitleFusion: ' Wells Fargo. NMLSR ID 399801',
  footer14Title:"Do not sell or share my personal information",
  //onekey
  onekeycancelMsg5: "You can also confirm your card by calling, ",
  onekeycancelTitle:"You're about to leave",
  onekeyCancelAndExit:"Yes, cancel",
  onekeygoBackBtn:"No, go back",
  onekeysignOn: "sign on",
  onekeyenrollNow: "enroll now.",
  onekeycancel:"If you cancel now, your card will not be confirmed.",
  onekeyterminateSummary2: "We can’t confirm your card online, but we can over the phone if you call ",
  //Titles
  titleNameSorry:"We’re working on a fix | Wells Fargo",
  titleNameError:"Confirmation Error | Wells Fargo",
  titleNameSucess:"Your card is ready to use | Wells Fargo",
  titleNameOLBConfirmcard:"Let’s confirm your new card | Wells Fargo",
  titleNameConfirmcard:"Confirm card details | Wells Fargo",
};
