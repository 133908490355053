import axios from 'axios';

export default (baseURL, headers = {}, history = null) => {
    const timeout = 10000000;
    const axiosInstance = axios.create({
        baseURL: baseURL,
        timeout,
        headers,
    });

    axiosInstance.interceptors.response.use(
        (response) =>  // this code is handling for Success 200.
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        (error) => { // this code is handling for other status 500,404.
            
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }
    );

    return axiosInstance;
};
