import { makeStyles, createTheme , ThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react'
import { deepPurple } from '@material-ui/core/colors'
import ErrorIcon from "@material-ui/icons/Error";
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import imageToRender from'../../global/img/wf/wf_icon_ui_visible_rgb_f1_wf_black.png';
import imageToNotVisibleRender from'../../global/img/wf/wf_icon_ui_not_visible_rgb_f1_wf_black.png';
import imageUmbrellaToRender from'../../global/img/wf/wf_icon_visible_black.png';
import imageUmbrellaToToNotVisibleRender from'../../global/img/wf/wf_icon_visibleoff_black.png';


const theme = createTheme ({
  palette: {
    primary: deepPurple
  }
})

export default function CcSampleComponent(props) {

  const useStyles = makeStyles(theme => ({
    root: {
      overflow: "hidden",
      width: '100%',
      borderRadius: 0,
      "& .MuiFilledInput-root": {
        backgroundColor: props.ccValid ? "#FFFFFF" : "#FCF7F6"
      },
      "& .MuiFilledInput-root:hover": {
        backgroundColor: props.ccValid ? "#F9F7F6" : "#FCF7F6",
        color: "#5A469B !important",
        "@media (hover: none)": {
          backgroundColor: "#F9F7F6",
        },

      },
      "& .Mui-focused": {
        marginTop: "0px !important",
      },


      "& .MuiFilledInput-root.Mui-focused": {
        //backgroundColor: "#F9F7F6",

      },
    },

  }));

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();
  let utilityLabel1 = '';
  if(props.language === "es"){
    utilityLabel1 = require("../resources/resources.es");
 }else{
   utilityLabel1 = require("../resources/resources.en");
 }
  return (<div>
    <FormControl className={(useStyles.formControl, classes.root)} variant="filled" autoComplete="off" id="FormControlId">
      <ThemeProvider theme={theme}>
        <InputLabel htmlFor={props.idName} error={!props.ccValid}>{
          props.ccValid
            ? props.inputLabel
            : (
              <div style={{ display: "flex" }}>
                {" "}
                <div>
                  <ErrorIcon />
                </div>
                <span style={{ marginTop: "3px", marginLeft: "5px" }}>
                  {props.inputLabel}
                </span>
              </div>
            )}</InputLabel>
        <FilledInput autoFocus className={classes.root}
          //type={props.showPassword ? 'text' : 'password'}
          type={'text'}
          inputProps={{ inputmode: 'numeric' }}
          value={props.value}
          mask={props.ccFormat}
          onPaste={(event) => { if (!props.isDeleteOrBackspace) { props.handlePaste(event) } }}
          onKeyDown={(event) => {props.handleKeyDown(event)}}
          onChange={(event) => { props.handleChange(event) }}
          onFocus={(event) => {props.onFocusInput(event)}}
          onClick={(event) => {props.onClickInput(event)}}
          onBlur={(event) => { props.onBlurInput(event.target.value) }}
          //autoComplete="cc-number"
          error={!props.ccValid}
          aria-describedby={props.ariaDscBy}
          id={props.idName}
          endAdornment={ 
            <InputAdornment position="end">
              {props.showPwd  ?
                <IconButton
                  //aria-label="toggle password visibility"
                  onClick={props.handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  aria-label={utilityLabel1.utilityLabel.showCardNumber}
                >
                 {(props.productType === "gpcc" || props.productType === "rubicon" ) ? <img id="gppcIcon" src={imageToRender}
                 alt ="Gpcc Icon" className="eca-confirm-ccn-show" style={{position: "absolute", maxWidth:"24px"}}></img>
                 :(props.productType === "onekey" || props.productType === "onekeyplus") ? <img id="umbrellaIcon" src={imageUmbrellaToRender}
                 alt = {utilityLabel1.utilityLabel.showCardNumber} className="eca-confirm-ccn-show" style={{position: "absolute", maxWidth:"24px"}}></img>
                 :<Visibility />}
                </IconButton> :
                <IconButton
                  //aria-label="toggle password visibility"
                  onClick={props.handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  aria-label={utilityLabel1.utilityLabel.hideCardNumber}
                >
                   {(props.productType === "gpcc"  || props.productType === "rubicon") ? <img id="gppcIconOff" src={imageToNotVisibleRender}
                    alt ="Gpcc Icon off" className="eca-confirm-ccn-hide" style={{position: "absolute", maxWidth:"24px"}}></img>
                    :(props.productType === "onekey" || props.productType === "onekeyplus")?
                    <img id="umbrellaIconOff" src={imageUmbrellaToToNotVisibleRender}
                     alt = {utilityLabel1.utilityLabel.hideCardNumber} className="eca-confirm-ccn-hide" style={{position: "absolute", maxWidth:"24px"}}></img>
                    :<VisibilityOff />}
                </IconButton>}
            </InputAdornment>
          }
        />
        <FormHelperText id="ccNumber-helper-text" error={!props.ccValid}>{props.ccValid ? "" : props.helperText} </FormHelperText>
      </ThemeProvider>
    </FormControl>

  </div>
  );
}
