export const utilityLabel = {
  //hammer
  hammerSorryTitle: "Lamentablemente, no pudimos confirmar su tarjeta.",
  hammerSorrySummary: "Para confirmar su tarjeta también puede llamar al número de servicio al cliente que aparece al dorso de su tarjeta.",
  //short.landing.js
  lblConfirm: 'Confirme <span style="white-space:nowrap;">su tarjeta</span>',
  lblFusionConfirm: 'Confirme los detalles de <span style="white-space:nowrap;">su tarjeta</span>',
  lblConfirmContent: 'Necesitamos un poco de información para confirmar que ha recibido <span style="white-space:nowrap;">su tarjeta.</span>',
  lblFieldRequired: "Todos los campos son obligatorios.",
  lblFusionConfirmContent: '',
  //card number
  inputLabelCC: "Número de tarjeta de crédito",
  lblCCMsgContent: 'Ingrese el número de cuenta completo que aparece en <span style="white-space:nowrap;">su tarjeta.</span>',
  lblCCMsgFusionContent: '',
  errValidationCCNumber: 'Ingrese un número de tarjeta de crédito de 15 o <span style="white-space:nowrap;">16 dígitos.</span>',
  showCardNumber: "Muestre número de tarjeta",
  hideCardNumber: "Oculte número de tarjeta",
  //amex nonamex img path
  ccAmexImgPath: require("../../global/img/card-cvv-amex.png"),
  ccNonAmexImgPath: require("../../global/img/card-cvv-nonamex.png"),
  imgAmexCaption: "Ejemplo de código de seguridad",
  imgNonAMexCaption: "Ejemplo de CVV",
  //cvv
  inputLabelCVVNonAmex1: "Código de seguridad",
  inputLabelCVVNonAmex: "¿Qué es un código de seguridad?",
  inputLabelCVVAmex: "Código de seguridad de la tarjeta de crédito",
  lblCVVNonAmexMsgContent: 'Ingrese el CVV de 3 dígitos que aparece al dorso de su tarjeta a la derecha de la línea <span style="white-space:nowrap;">de firma.</span>',
  lblCVVAmexMsgContent: 'Ingrese el código de seguridad de 4 dígitos que aparece en el frente de <span style="white-space:nowrap;">su tarjeta.</span>',
  errValidationCVVAmex: 'Ingrese un código de seguridad de <span style="white-space:nowrap;">4 dígitos.</span>',
  errValidationCVVNonAmex: 'Ingrese un CVV de <span style="white-space:nowrap;">3 dígitos.</span>',
  securityCodeDescTitle: "¿Qué es esto?",
  securityCodeDesc: "El código de seguridad es un número de 3 o 4 dígitos específico para su tarjeta.<br /><br /><strong>¿Dónde se encuentra ubicado?</strong><br />",
  securityCodeDesc1: "Mastercard y Visa: el número de 3 dígitos aparece al dorso de su tarjeta, cerca del centro.<br />",
  securityCodeDesc2: "American Express: el número de 4 dígitos aparece en el frente de su tarjeta, a la derecha.",
  //DOB
  inputLabelDOB: " Fecha de nacimiento (mm/dd/aaaa)",
  lblDOBMsgContent: 'Ingrese la fecha de nacimiento del titular <span style="white-space:nowrap;">de cuenta.</span>',
  lblDOBMsgFusionContent: '',
  errValidationDOB: 'Ingrese la fecha de nacimiento del titular de cuenta con formato de 2 dígitos para el mes/2 dígitos para el día/4 dígitos para '+
  '<span style="white-space:nowrap;">el año.</span>',
  //ssn
  inputLabelSSN: " SSN o ITIN (últimos 4 dígitos)",
  lblSSNMsgContent: 'Ingrese los últimos 4 dígitos del número de Seguro Social o número de identificación personal del contribuyente del titular '+
  '<span style="white-space:nowrap;">de cuenta.</span>',
  errValidationSSN: 'Ingrese <span style="white-space:nowrap;">4 dígitos.</span>',
  //signon and enroll
  signOn: "Inicie sesión",
  or: " o ",
  enrollNow: "inscríbase ahora",
  enrollNowThankyou: "inscríbase ahora",
  //button text
  btnCancel: "Cancele",
  btnConfirm: "Confirme",
  buttonClose: "close",

  cardTypeAemx: "AMEX",
  dobFormat: "##/##/####",
  cardTypeNonAMex: "NONAMEX",
  idCCName: "cardNumber",
  idCVVName: "cvv",
  idSSNName: "ssn",
  enrollNowBtn: "Inscríbase ahora",
  //thankyou page
  thankyouHeader: '¡Buenas noticias, su tarjeta <span style="white-space:nowrap;">está confirmada!</span>',
  thankyouPageMessage1: 'Su tarjeta y todas las tarjetas asociadas con su cuenta que <span style="white-space:nowrap;">termina en  </span>',
  thankyouPageMessage2: " ya están listas para usar.",
  thankyouConfirmAdviseMessage: 'Retire la etiqueta que aparece al frente de su tarjeta y firme al dorso de  <span style="white-space:nowrap;">la tarjeta.</span>',
  enroll: "inscríbase ahora.",
  thankyouPageMessage3: ' en la banca por Internet para agregar su tarjeta a una billetera digital, explorar los beneficios de su tarjeta '+
  '<span style="white-space:nowrap;">y más.</span>',
  CConboardingpreferencesSVG: require("../../global/img/CC_onboarding_preferences.svg").default,
  accountSummarySmallSVG: require("../../global/img/first_time_experience-account_summary_small.svg").default,
  CConboardingpreferences: require("../../global/img/CC_onboarding_preferences.png"),
  accountSummarySmall: require("../../global/img/first_time_experience-account_summary_small.png"),
  altEqualHouseLogo: "Equal Housing Lender logo",
  altCConBoardingImg: "Beneficios de la neuva tarjeta",
  titleCConBoardingImg: "Beneficios de la neuva tarjeta",
  titleEnroll: "Open new page to enroll to Wells Fargo Online Banking",
  titleSignOn: "Open new page to sign on to Wells Fargo Online Banking",
  altAcctSummaryImg: 'Su tarjeta está confirmada!',
  titleAcctSummaryImg: 'Su tarjeta está confirmada!',
  hrefSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=activateCard&LOB=CONS&langPref=SPN",
  hrefEnroll: "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard&langPref=SPN",
  hrefThankyouSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=accountSummary&LOB=CONS&langPref=SPN",
  hrefEnrollNowBtnDone:"https://www.wellsfargo.com/es/mobile-online-banking/manage-accounts/",
  thankyouPage2Message1: "Ya está listo.",
  thankyouPage2Message2: "Su tarjeta se ha confirmado.",
  thankyouPage2Message3: "Comience a comprar, agregue la tarjeta a una billetera digital o inicie sesión para",
  thankyouPage2Message4 : " seguir explorando las características de su tarjeta.",
  enrollOnlinebankingBtn: "EInscríbase en Banca por Internet",
  signOnGoToAccountsBtn: "Vaya a las cuentas",
  thankyouPage2Message5: "¡Su tarjeta está lista para usar!",
  //Session and cancel Modal popup
  ecaErrorMessage1: '<p className="eca-error-msg1">Su sesión ha expirado. Intente confirmar su tarjeta <span style="white-space:nowrap;">de nuevo.</span></p>',
  continueBtn: "Continúe",
  idleModalTitle: "¿Desea continuar su sesión?",
  idleModalMsg1: "Por motivos  de seguridad, su sesión expirará a la(s) ",
  idleModalMsg2: ", a menos que elija continuar.",
  cancelTitle: "Cancele",
  cancelMsg1: "¿Está seguro de que desea salir?",
  cancelMsg2: "Si cancela ahora, su tarjeta no se confirmada.",
  cancelMsg3: 'Si es cliente de la Banca por Internet <span lang="en">Wells Fargo Online</span>',
  cancelMsg4: ', puede confirmar por Internet en cualquier momento. ',
  cancelMsg5: "También puede confirmar su tarjeta llamando al número de servicio al cliente que aparece al dorso de su tarjeta.",
  cancelMsg6: "Por su seguridad, cierre su navegador.",
  cancelMsgContent: 'Si es cliente de la Banca por Internet <span lang="en">Wells Fargo Online</span><sup>&reg;</sup>, puede confirmar por Internet en cualquier momento. ',
  cancelExitBtn: "Cancele y salga",
  fusioncancelExitBtn: "Sí, cancelar",
  goBackBtn: "Atrás",
  fusiongoBackBtn: "No, regresar",
  closeDialog: "Cerrar diálogo",
  //fraud.js
  terminateTitle: 'Lamentablemente, su tarjeta no <span style="white-space:nowrap;">está confirmada</span>',
  terminateTitleFusion: 'Lamentablemente, no pudimos confirmar su tarjeta.',
  terminateSummary1: 'Intentemos confirmar su tarjeta a través de la Banca por Internet <span lang="en">Wells Fargo Online</span><sup>&reg;</sup> ',
  terminateSummary2: 'También puede confirmar su tarjeta llamando al número de servicio al cliente que aparece al dorso de '+
  '<span span style="white-space:nowrap;" > su tarjeta.</span>',
  terminateSummary4: 'puede confirmar su tarjeta llamando al número de servicio al cliente que aparece al dorso de '+
  '<span span style="white-space:nowrap;" > su tarjeta.</span>',
  terminateSummary3: 'Por su seguridad, cierre <span style="white-space:nowrap;">su navegador.</span>',
  terminateSummary5: 'Para confirmar su tarjeta también puede llamar al número de servicio al cliente que aparece al dorso de su tarjeta.',
  //systemerror.js
  switchoffTitle: 'Confirme <span style="white-space:nowrap;">su tarjeta</span>',
  switchoffSummary1: 'Puede confirmar su tarjeta a través de la Banca por Internet <span lang="en">Wells Fargo Online</span><sup>&reg;</sup>. ',
  switchoffSummary2: 'Si prefiere confirmar su tarjeta por teléfono, <span style="white-space:nowrap;">puede llamarnos </span>',
  switchoffSummary3: 'Por su seguridad, cierre <span style="white-space:nowrap;">su navegador.</span>',
  switchoffPhoneTitle: '1-866-537-8424',
  switchoffPhoneHref: 'tel:+18665378424',
  switchoffSummary4: 'No podemos confirmar tu tarjeta en este momento.',
  switchoffSummary5: 'Para confirmar su tarjeta por teléfono, puede llamarnos al ',
  switchoffSummary6: 'Estamos trabajando para resolver un problema.',
  switchoffSummary7: 'No podemos confirmar su tarjeta por Internet en este momento, pero le asistiremos con gusto por teléfono si llama al ',
  //customerservice.js
  customerServiceTitle: 'Su tarjeta no <span style="white-space:nowrap;">está confirmada</span>',
  customerServiceSummary1: 'Si necesita ayuda para confirmar su tarjeta, puede llamarnos al número de servicio al cliente que aparece al dorso de '+
  '<span style="white-space:nowrap;">su tarjeta.</span>',
  customerServiceSummary2: 'Por su seguridad, cierre <span style="white-space:nowrap;">su navegador.</span>',
  //message for 3 failed attempt
  warningMsgforFailedAttempt: '<p className="eca-error-msg1">Los detalles proporcionados no coinciden con nuestros registros. Por favor, '+
  '<span style = "white-space:nowrap;">inténtelo de nuevo.</span></p>',

   //olb confirm Card Landing
   lblOlbConfirm:'¡Confirmemos su <span style="white-space:nowrap;">nueva tarjeta!</span>',
   lblOlbConfirmLandingMsg1:'Inicie sesión para confirmar en 2 sencillos pasos.',
   lblOlbConfirmLandingMsg2:'¿No tiene acceso a la cuenta por Internet?',
   signOnConfirm: "Inicie sesión",
   confirmWithFewDetails: "Confírmela con unos pocos detalles.",
   hrefSignOnConfirm: "https://connect.secure.wellsfargo.com/auth/login/ulink?origin=cob&serviceType=activateCard",


     //Attune confirm Card Landing
  lblAttuneConfirm: '¡Confirmemos su <span style="white-space:nowrap;">nueva tarjeta!</span>',
  signOntoConfirm: "Inicie sesión para confirmarla",
  noOnlineAccount: "¿No tiene acceso a la cuenta por Internet?",
  enrolltoConfirm: "Inscríbase para confirmarla",
  hrefSignOnAttuneConfirm: "https://connect.secure.wellsfargo.com/auth/login/ulink?origin=cob&serviceType=activateCard",
  hrefEnrollOntoAttuneConfirm: "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard",

  //footer
  footer1Title: 'Privacidad, cookies, seguridad y asuntos legales',
  footer2Title: 'Aviso sobre recopilación de datos',
  footer3Title: 'Términos generales de uso',
  footer4Title: 'Opciones para anuncios',
  footer5Title: 'Reporte el fraude',
  footer6Title: 'Mapa del sitio',
  footer7: 'Acerca de Wells Fargo',
  footer7Title: 'Acerca de <span lang="en">Wells Fargo</span>',
  footer8Title: 'Empleo',
  footer9Title: 'Diversidad y accesibilidad',
  footer10Title: 'Página principal',
  footer1Href: 'https://www.wellsfargo.com/es/privacy-security/',
  footer2Href: 'https://www.wellsfargo.com/es/privacy-security/notice-of-data-collection/',
  footer3Href: 'https://www.wellsfargo.com/es/privacy-security/terms/',
  footer4Href: 'https://www.wellsfargo.com/es/privacy-security/privacy/online/#adchoices',
  footer5Href: 'https://www.wellsfargo.com/es/privacy-security/fraud/report/',
  footer6Href: 'https://www.wellsfargo.com/es/sitemap',
  footer7Href: 'https://www.wellsfargo.com/es/about/',
  footer8Href: 'https://www.wellsfargo.com/about/careers/',
  footer9Href: 'https://www.wellsfargo.com/es/about/diversity/',
  footer10Href: 'https://www.wellsfargo.com/es/',
  footerContentFusion: '<ul className="mastfoot-ada-links">  <li>       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/" title="Privacidad, cookies, seguridad y asuntos legales" tabIndex="-1" aria-label="Privacidad, cookies, seguridad y asuntos legales">Privacidad, cookies, seguridad y asuntos legales</a>  '+
  '</li>    <li >       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/notice-of-data-collection/" title="Aviso sobre recopilación de datos" tabIndex="-1" aria-label="Aviso sobre recopilación de datos">Aviso sobre recopilación de datos</a>  </li>  '+
  '  <li >       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/terms/" title="Términos generales de uso" tabIndex="-1" aria-label="Términos generales de uso">Términos generales de uso</a>  </li>  '+
  '  <li >       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/privacy/online/#adchoices" title="Opciones para anuncios" tabIndex="-1" aria-label="Opciones para anuncios">Opciones para anuncios</a>  </li>  '+
  '  <li c>       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/fraud/report/" title="Reporte el fraude" tabIndex="-1" aria-label="Reporte el fraude">Reporte el fraude</a>  </li>  '+
  '<li >       <a href="https://www.wellsfargo.com/es/sitemap" title="Mapa del sitio" tabIndex="-1" aria-label="Mapa del sitio">Mapa del sitio</a>  </li>  '+
  '  <li >       '+
  '<a href="https://www.wellsfargo.com/es/about/" title="Acerca de Wells Fargo" tabIndex="-1" aria-label="Acerca de Wells Fargo">Acerca de <span lang="en">Wells Fargo</span></a>  </li>  '+
  '  <li >       <a href="https://www.wellsfargo.com/about/careers/" title="Empleo" tabIndex="-1" aria-label="Empleo">Empleo</a>  </li>  '+
  '  <li >       '+
  '<a href="https://www.wellsfargo.com/es/about/diversity/" title="Diversidad y accesibilidad" tabIndex="-1" aria-label="Diversidad y accesibilidad">Diversidad y accesibilidad</a>  </li>  '+
  '  <li>       <a href="https://www.wellsfargo.com/es/" title="Página principal" tabIndex="-1" aria-label="Página principal">Página principal</a>  '+
  '</li></ul>',
  footerContent: '<ul className="list-inline mastfoot-links mb-0">  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/" title="Privacidad, cookies, seguridad y asuntos legales" tabIndex="-1" aria-label="Privacidad, cookies, seguridad y asuntos legales">Privacidad, cookies, seguridad y asuntos legales</a>  '+
  '</li>  <li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/notice-of-data-collection/" title="Aviso sobre recopilación de datos" tabIndex="-1" aria-label="Aviso sobre recopilación de datos">Aviso sobre recopilación de datos</a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/terms/" title="Términos generales de uso" tabIndex="-1" aria-label="Términos generales de uso">Términos generales de uso</a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/privacy/online/#adchoices" title="Opciones para anuncios" tabIndex="-1" aria-label="Opciones para anuncios">Opciones para anuncios</a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/privacy-security/fraud/report/" title="Reporte el fraude" tabIndex="-1" aria-label="Reporte el fraude">Reporte el fraude</a>  </li>'+
  '<li className="list-inline-item divider">|</li>  '+
  '<li className="list-inline-item">       <a href="https://www.wellsfargo.com/es/sitemap" title="Mapa del sitio" tabIndex="-1" aria-label="Mapa del sitio">Mapa del sitio</a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/about/" title="Acerca de Wells Fargo" tabIndex="-1" aria-label="Acerca de Wells Fargo">Acerca de <span lang="en">Wells Fargo</span></a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/about/careers/" title="Empleo" tabIndex="-1" aria-label="Empleo">Empleo</a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/about/diversity/" title="Diversidad y accesibilidad" tabIndex="-1" aria-label="Diversidad y accesibilidad">Diversidad y accesibilidad</a>  </li>  '+
  '<li className="list-inline-item divider">|</li>  <li className="list-inline-item">       '+
  '<a href="https://www.wellsfargo.com/es/" title="Página principal" tabIndex="-1" aria-label="Página principal">Página principal</a>  '+
  '</li></ul>',
  footer11Title: '1999-',
  footer12Title: new Date().getFullYear(),
  footer13Title: ' <span lang="en">Wells Fargo</span>. Todos los derechos reservados. NMLSR ID 399801',
  footer13TitleFusion: ' Wells Fargo. NMLSR ID 399801',
  footer14Title:"No vendan ni compartan mi información personal",
  //onekey
  onekeycancelTitle:"Estás a punto de irte",
  onekeycancelMsg5:"También puede confirmar su tarjeta llamando al ",
  onekeysignOn: "Inicie sesión",
  onekeyenrollNow: "inscríbase ahora.",
  onekeycancel:"Si cancela ahora, su tarjeta no se confirmará.",
  onekeyterminateSummary2:"No podemos confirmar su tarjeta por Internet en este momento, pero le asistiremos con gusto por teléfono si llama al ",
  //Titles
  titleNameSorry:"Estamos trabajando en una solución | Wells Fargo",
  titleNameError:"Error de confirmación|Wells Fargo",
  titleNameSucess:"Su tarjeta está lista para usar | Wells Fargo",
  titleNameOLBConfirmcard:"Confirmemos su nueva tarjeta | Wells Fargo",
  titleNameConfirmcard:"Confirme los detalles de la tarjeta | Wells Fargo",
}
