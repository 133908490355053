import parse from "html-react-parser";
import React from "react";

function GPCCFraudComponent(props) {
  if(props.errorCode === "ECA_205"){
    return (
    <React.Fragment>
        <p className="eca-terminate-summary2">
          {parse(props.utilityLabel1.utilityLabel.terminateSummary4)}
        </p>
    </React.Fragment>
    );
  }else{
    return (
      <React.Fragment>
      <p className="eca-terminate-summary1">
        {parse(props.utilityLabel1.utilityLabel.terminateSummary1)}
        <a href={props.utilityLabel1.utilityLabel.hrefSignOn}
          className="underlined"
          id="signOn"
          onClick={() => props?.trackEvents("FraudSorry", "SignOn", props.sessionID)}
          title="Click this link to navigate SignOn page."
        >
          {props.utilityLabel1.utilityLabel.signOn}
        </a>{" "}
        {props.utilityLabel1.utilityLabel.or}{" "}
        <a href={props.utilityLabel1.utilityLabel.hrefEnroll}
          className="underlined"
          id="enroll"
          style={{ whiteSpace: "nowrap" }}
          onClick={() =>
            props?.trackEvents("FraudSorry ", "Enroll", props.sessionID)
          }
          title="Click this link to navigate Enroll page."
        >
          {props.utilityLabel1.utilityLabel.enrollNow}
        </a>{" "}
      </p>

      <p className="eca-terminate-summary2">
        {parse(props.utilityLabel1.utilityLabel.terminateSummary2)}
      </p>
  </React.Fragment>
   );
  }
  }

export default GPCCFraudComponent;
