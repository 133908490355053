import React from "react";
import parse from "html-react-parser";

function BiltSorryComponent(props) {

  return (
    <React.Fragment>
                  <p className="eca-switchoff-summary1">
                    {parse(props.utilityLabel1.utilityLabel.switchoffSummary1)}
                  </p>
                </React.Fragment>
  );
}

export default BiltSorryComponent;
