export const ServiceApi = {

    XAPIKEY: '',
    PUBLICIP: '',
    XFORWARDEDFOR: '',
    NuData: {
        JENNYURL: '',
        ATADUNURL: ''
    },
    AdrumData: {
        ADRUMKEY: '',
        BEACONURLHTTPS: '',
        XDENABLED: ''
    },
    OLB_SIGN_ON_ENG_URL: '',
    OLB_ENROLL_ENG_URL: '',
    OLB_SIGN_ON_SPN_URL: '',
    OLB_ENROLL_SPN_URL: '',
    SIGN_ON_ACCT_ENG_URL: '',
    ENROLL_BANK_ENG_URL: '',
    SIGN_ON_ACCT_SPN_URL: '',
    ENROLL_BANK_SPN_URL: '',
    ENROLLMENT_ENG_URL: '',
    ENROLLMENT_SPN_URL: '',
    FOOTER_ENG_URL:'',
    FOOTER_SPN_URL:'',

};

