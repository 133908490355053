import { makeStyles, createTheme , ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format'
import { deepPurple } from '@material-ui/core/colors'
import ErrorIcon from "@material-ui/icons/Error";

const theme = createTheme ({
  palette: {
    primary: deepPurple
  }
})

export default function Textbox(props) {

  const useStyles = makeStyles(theme => ({

    root: {
      overflow: "hidden",
      width: '100%',
      borderRadius: 0,
      "& .MuiFilledInput-root": {
        backgroundColor: props.ccValid ? "#FFFFFF" : "#FCF7F6"
      },
      "& .MuiFilledInput-root:hover": {
        backgroundColor: props.ccValid ? "#F9F7F6" : "#FCF7F6",
        color: "#5A469B !important",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "#F9F7F6",
        },
      },
      "& .Mui-focused": {
        marginTop: "0px !important",
      },


      "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor: "#F9F7F6",

      },
    },

  }));

  const classes = useStyles();
  const inputmode = props.inputmode !== undefined ? props.inputmode : null;
  return (
    <form className={classes.root} noValidate autoComplete="off">
      <ThemeProvider theme={theme}>
        <NumberFormat
          customInput={TextField}
          inputProps={{ inputmode: inputmode  }}
          format={props.field === 'card' ? props.ccFormat : props.dobFormat}
          label={
            props.ccValid
              ? ( <div> {props.inputLabel} <span className="sr-only"> {props.helperTextADA}</span> </div>)
              : (
                <div style={{ display: "flex" }}>
                  {" "}
                  <div>
                    <ErrorIcon />
                  </div>
                  <span style={{ marginTop: "3px", marginLeft: "5px" }}>
                    {props.inputLabel}
                  </span>
                </div>
              )}
          value={props.value}
          aria-describedby={props.ariaDscBy}
          id="dob"
          variant='filled'
          onChange={(event) => props.handleChange(props.field, event.target.value, event)}
          onKeyDown={(event) => props.onKeyDownInput(event)}
          onFocus={props.onFocusInputField}
          onBlur={(event) => props.onBlurInput(event.target.value)}
          helperText={props.ccValid ? "" : props.errMsg}
          error={!props.ccValid}
        />
      </ThemeProvider>
    </form>
  );
}