import React from "react";
import parse from "html-react-parser";
import * as utility from "../../../utilities/utils";

export function GpccModalComponent(props){
  let params = utility.getParams();
  let utilityLabel1 ='';
  if(params.language === "es"){
     utilityLabel1 = require("../../resources/resources.es");
  }else{
    utilityLabel1 = require("../../resources/resources.en");
  }
    return (
      <div className="row">
      <div className="col-12">
        <p className="modal-eca-alternativehelp-primary-desc">
          {utilityLabel1.utilityLabel.cancelMsg1}
        </p>
        <p className="modal-eca-alternativehelp-secondary-desc">
          {utilityLabel1.utilityLabel.cancelMsg2}
        </p>
        <p className="modal-eca-alternativehelp-desc1">
        {parse(utilityLabel1.utilityLabel.cancelMsgContent)}
        {<React.Fragment>
                <a href={utilityLabel1.utilityLabel.hrefSignOn}
                  id="signOn"
                  onClick={() => props.trackEvents("Cancel", "SignOn",props.sessionID)}
                  className="underlined"
                  title="Click this link to navigate SignOn page."
                >
                  {utilityLabel1.utilityLabel.signOn}
                </a>{" "}
                {utilityLabel1.utilityLabel.or}
                <a href={utilityLabel1.utilityLabel.hrefEnroll}
                  id="enroll"
                  onClick={() => props.trackEvents("Cancel", "Enroll",props.sessionID)}
                  className="underlined"
                  title="Click this link to navigate Enroll page."
                >
                  {utilityLabel1.utilityLabel.enrollNow}
                </a>
                </React.Fragment>}
                </p>{
                      <p className="modal-eca-alternativehelp-desc2">
                      {utilityLabel1.utilityLabel.cancelMsg5}
                         </p>
                }
                {
                  (props.isFusionStyle) ?
                      (<p className="modal-eca-alternativehelp-desc2">
                      {utilityLabel1.utilityLabel.cancelMsg6}
                         </p>): null
                }
                 </div>
          </div>
            )

}

export default GpccModalComponent;
