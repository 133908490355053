import {utilityCommonLabel} from "./resources.common";
const utilityCasaLabel = {
  //Thankyou
  CConboardingpreferencesSVG:
    require("../../global/img/CC_onboarding_preferences.svg").default,
  accountSummarySmallSVG:
    require("../../global/img/bilt_first_time_experience-account_summary_small.svg").default
      ,
  CConboardingpreferences:
    require("../../global/img/CC_onboarding_preferences.svg").default,
  accountSummarySmall:
    require("../../global/img/bilt_first_time_experience-account_summary_small.svg").default
      ,
  thankyouHeader:
    'Great news, your card <span style="white-space:nowrap;">is confirmed!</span>',
  thankyouPageMessage1:
    "Your card and all cards associated with your account ending in",
  thankyouPageMessage2: "are now ready for use.",
  thankyouConfirmAdviseMessage: "",
  signOn: "Sign on",
  or: "or ",
  enroll: "enroll",
  enrollNowThankyou: "Enroll now",
  enrollNow: "enroll now.",
  thankyouPageMessage4: 'Add your card to a digital wallet, make rent payments, view recent transactions, and more in the Bilt Rewards App. Visit biltrewards.com/app for details.',
  thankyouPageMessage3:
    ' to Wells Fargo Online<sup>&reg;</sup> to make card payments, turn your card off and on, set up credit card alerts, <span style="white-space:nowrap;">and more.</span>',
  altEqualHouseLogo: "Equal Housing Lender logo",
  altCConBoardingImg: "New card benefits",
  titleCConBoardingImg: "New card benefits",
  titleEnroll: "Open new page to enroll to Wells Fargo Online Banking",
  titleSignOn: "Open new page to sign on to Wells Fargo Online Banking",
  altAcctSummaryImg: "Your card is confirmed!",
  titleAcctSummaryImg: "Your card is confirmed!",
  hrefSignOn:
    "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=activateCard&LOB=CONS",
  hrefEnroll:
    "https://oam.wellsfargo.com/oamo/identity/enrollment?sourceApp=activateCard",
  hrefThankyouSignOn: "https://connect.secure.wellsfargo.com/auth/login/present?origin=cob&loginMode=jukePassword&serviceType=accountSummary&LOB=CONS",

  //Session and cancel modal popup
  ecaErrorMessage1:
    '<p className="eca-error-msg1"> Your session has timed out. Please try to confirm your <span style="white-space:nowrap;">card again.</span></p>',
  continueBtn: "Continue",
  idleModalTitle: "Do you want to continue your session?",
  idleModalMsg1: "For security reasons, your session will time out at ",
  idleModalMsg2: " unless you choose to continue.",
  cancelTitle: "Cancel",
  cancelMsg1: "Are you sure you want to leave?",
  cancelMsg2: "If you cancel now, your card won’t be confirmed.",
  cancelMsg3: "If you're a Wells Fargo Online",
  cancelMsg4: "customer, you can confirm online anytime. ",
  cancelMsg5:
    "To confirm your card, call ",
  cancelMsg6: "For your security, please close your browser.",
  cancelMsgContent:
    "If you're a Wells Fargo Online<sup>&reg;</sup> customer, you can confirm online anytime. ",
  cancelExitBtn: "Cancel and Exit",
  goBackBtn: "Go Back",
  closeDialog: "Close dialog",
  //fraud.js
  terminateTitle: 'Unfortunately, your card <span style="white-space:nowrap;">isn’t confirmed</span>',
  terminateSummary1: 'If you\'re a Wells Fargo Online<sup>&reg;</sup> customer, you can confirm your card online anytime. ',
  terminateSummary2: 'To confirm your card, call ',
  terminateSummary3: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  //systemerror.js
  switchoffTitle: 'Confirm <span style="white-space:nowrap;">your card</span>',
  switchoffSummary2: 'To confirm your card, call ',
  switchoffSummary1: 'If you\'d like to confirm your card over the phone, you can call the customer service phone number on the back of your card. ',
  switchoffSummary3: 'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  switchoffPhoneTitle: '1-800-723-5533.',
  switchoffPhoneHref: 'tel:18007235533.',
  signOnSorry: "sign on",
  //customerservice.js
  customerServiceTitle:
    'Your card is <span style="white-space:nowrap;">not confirmed</span>',
  customerServiceSummary1:
    "If you need help confirming your card, you can give us a call at the customer service number located on the back of" +
    '<span style="white-space:nowrap;">your card.</span>',
  customerServiceSummary2:
    'For your security, please close <span style="white-space:nowrap;">your browser.</span>',
  //message for 3 failed attempt
  warningMsgforFailedAttempt:
    '<p className="eca-error-msg1">The details you’ve provided don\'t match our records. Please <span style="white-space:nowrap;">try again.</span></p>',
};
export const utilityLabel={...utilityCommonLabel,...utilityCasaLabel};
