import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ErrorIcon from "@material-ui/icons/Error";

export default function PasswordTextbox(props) {
  const useStyles = makeStyles((theme) => ({
    root: {
      overflow: "hidden",
      borderRadius: 0,
      color: "none",
      "& .MuiFilledInput-root": {
        backgroundColor:
          props.idName === "cvv"
            ? props.errMsg.cvv.length === 0
              ? "#FFFFFF"
              : "#FCF7F6 !important"
            : props.errMsg.ssn.length === 0
              ? "#FFFFFF"
              : "#FCF7F6 !important",
      },
      "& .MuiFilledInput-root:hover": {
        backgroundColor: "#F9F7F6",
        color: "#5A469B",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "#F9F7F6",
        },
      },
      "& .MuiFilledInput-root.Mui-focused": {
        backgroundColor:
          props.idName === "cvv"
            ? props.errMsg.cvv.length === 0
              ? "#F9F7F6"
              : "#FCF7F6 !important"
            : props.errMsg.ssn.length === 0
              ? "#F9F7F6"
              : "#FCF7F6 !important",
      },
      "& .Mui-focused": {
        marginTop: "0px !important",
      },
    },

    focused: {},
  }));
  const classes = useStyles();
    const inputmode = props.inputmode !== undefined ? props.inputmode : null;

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        style={{ width: "100%" }}
        inputProps={{ maxLength: props.inputMaxLength, inputmode: inputmode }}
        onChange={(event) => props.handleChangeInput(event)}
        onBlur={(event) => props.onBlurInput(event)}
        onKeyDown={(event) => props.onKeyDownInput(event)}
        type={props.inputType}
        onFocus={props.onFocusInputField}
        value={props.value}
        id={props.idName}
        aria-describedby={props.ariaDscBy}
        variant="filled"
        label={
          props.idName === "cvv" ? (
            props.errMsg.cvv.length === 0 ? ( <div> {props.inputLabel} <span className="sr-only"> {props.helperTextADA}</span> </div>) : (
              <div style={{ display: "flex" }}>
                {" "}
                <div>
                  <ErrorIcon />
                </div>
                <span style={{ marginTop: "3px", marginLeft: "5px" }}>
                  {props.inputLabel}
                </span>
              </div>
            )
          ) : props.errMsg.ssn.length === 0 ? (
             <div> {props.inputLabel} <span className="sr-only"> {props.helperTextADA}</span> </div>
          ) : (
            <div style={{ display: "flex" }}>
              {" "}
              <div>
                <ErrorIcon />
              </div>
              <span style={{ marginTop: "3px", marginLeft: "5px" }}>
                {props.inputLabel}
              </span>
            </div>
          )
        }
        helperText={
          props.idName === "cvv" ? props.errMsg.cvv : props.errMsg.ssn
        }
        error={
          props.idName === "cvv"
            ? props.errMsg.cvv.length === 0
              ? false
              : true
            : props.errMsg.ssn.length === 0
              ? false
              : true
        }
      />
    </form>
  );
}
