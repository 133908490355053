import parse from "html-react-parser";
import React from "react";
import Header from "../shared/controls/header";
import Footer from "../shared/controls/footer";
import * as utility from "../../utilities/utils";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function RubiconThankyouComponent(props) {
  let params = utility.getParams();
  let utilityLabel1 = require("../resources/resources.rubicon");
  return (
     <HelmetProvider>
        <>
          <Helmet>
            <html lang={params.language} id="html-tag"></html>
          </Helmet>
    <div className="d-flex flex-column h-100 dce-bgpage  pace-done" id="rubiconPage">
       <Header/>
       <main className="main-wrapper flex-shrink-0 subtitle-0">
      <div className="container my-3">
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-2 pb-md-4">
                  <img
                    id="imgRubiconCheckmark"
                    src={require ("../../global/img/wf/icon_checkmark_approved.png")}
                     alt=""
                    className="img-fluid"
                    style={{ width: "100%", maxWidth: "88px" }}
                  />
            <h1 className="eca-confirm-title text-center text-divider-yellow text-divider-yellow-md-0 pb-2 pb-md-0 pt-3" id="eca-confirm-title">
              {parse(utilityLabel1.utilityLabel.thankyouPageMessage1)}</h1>
            <h2 className="eca-confirm-summary1 pt-3">{parse(utilityLabel1.utilityLabel.thankyouPageMessage2)}</h2>
          </div>
        </div>
        <div className="row cta-container">
          <div className="col-12 text-center">
             <a
           type="button" role="button" tabIndex="0" className="btn btn-all-primary m-0 mb-3 eca-btn-done"
           onClick={() => {  window.location.href = utilityLabel1.utilityLabel.hrefBtnDone }}
           id="btnDone"
         >
         {utilityLabel1.utilityLabel.btnDone}
         </a>
          </div>
        </div>

      </div>
      </main>
      <Footer />
    </div>
     </>
          </HelmetProvider>
  );
}

export default RubiconThankyouComponent;