import React from 'react';
import parse from 'html-react-parser';
import * as utility from "../../../utilities/utils";
import imageToRender from "../../../global/img/wf/icon-msg-alert-warning.png";

export const WarningMessageComponent = (props) => {

  return (
    <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <div className="alert alert-light dce-msg-container mb-3" role="alert">
        <table className="dce-msg" role="presentation">
          <tbody>
          <tr>
            <td className="text-center align-top dce-msg-icon">
              {(utility.getPartner() === "gpcc" || utility.getPartner() === "rubicon" || utility.getPartner() === "onekey" || utility.getPartner() === "onekeyplus") ?
                <img id="imgWarning" src={imageToRender} className="msg-warning" alt="" /> :
                <span className="fa fa-exclamation-circle fa-2x msg-error"></span>
              }
            </td>
            <td className="text-left align-text-top dce-msg-text">
              {parse(props.ecaErrorMessage1)}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};