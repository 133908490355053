import React, { useState, useEffect} from 'react';
import * as utility from './utilities/utils'
import MainContent from './main-content';
import { Helmet, HelmetProvider } from 'react-helmet-async';


function App(props) {
  const [eventVal, setEventVal] = useState(0);
  const [sessionID, setsessionID] = useState(0);


  useEffect(() => {
    const eventID = utility.getUniqueID()
    setEventVal(eventID);
    const sessionIDVal=utility.getUniqueID()
    setsessionID(sessionIDVal)
    if(utility.getPartner()==='bilt' || utility.getPartner()=== 'choice' || utility.getPartner()==='ChoiceConv') {
      import ('./App.css');
    }
    else {
      import ('./style/fusion.css');

    }
  }, []);

  let language = "en";
  if (utility.getLanguage() === "spanish") {
    language = "es";
  }

  return (
    <HelmetProvider>
    <>
      <Helmet>
        <html lang={language} id="html-tag"></html>
      </Helmet>
      {
        (eventVal !== 0 ) && (sessionID !== 0 ) && <MainContent eventID={eventVal} sessionID={sessionID} {...props}></MainContent>
      }
    </>
    </HelmetProvider>
  );
}

export default App;
