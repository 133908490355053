import React from "react";
import parse from "html-react-parser";

function HammerSorryComponent(props) {

  return (
    <React.Fragment>
       <div className="container my-3">
          <div className="row eca-terminate-fraud-container">
                <div className="col-12 text-center pb-2 pb-md-4">
                      <h1 className="eca-terminate-system-title text-center pb-2 pb-md-0 pt-0 pt-sm-1" id="eca-confirm-title">{parse(props.title)}</h1>
                      <p className="eca-terminate-system-summary1 text-left text-md-center pt-3">{parse(props.summary)}</p>
                </div>
            </div>
       </div>
    </React.Fragment>
  );
}

export default HammerSorryComponent;
