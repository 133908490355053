import React, {  useEffect } from 'react';
import { ServiceApi } from '../../environment'

function Appadrum(props) {
    const scriptLoad = () => {
        var AppD = document.getElementById("AppD");
        if (AppD) {
            document.body.removeChild(AppD);
        }
        var script = document.createElement('script')
        script.type = "text/javascript";
        script.src = '../../script/adrum-latest.js';
        script.id = "AppD";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
    }

    const scriptLang = () => {
        var param = {},
            pairs = document.URL.split("?").pop().split("&");
        for (var i = 0, p; i < pairs.length; i++) {
            p = pairs[i].split("=");
            param[p[0]] = p[1];
        }

        if (param.language === "es") {
            document.documentElement.lang = 'es'
        } else {
            document.documentElement.lang = 'en'
        }
    }
    
    const appD = () => {
        (function () {
            window["adrum-start-time"] = new Date().getTime();
            (function (config) {
                config.appKey = ServiceApi.AdrumData.ADRUMKEY;  // Should change based on application and environment
                config.adrumExtUrlHttp = "/script";
                config.adrumExtUrlHttps = "/script";
                //config.beaconUrlHttp = 'https://uat1-eum-appdynamics.wellsfargo.com:7001'
                config.beaconUrlHttps = ServiceApi.AdrumData.BEACONURLHTTPS

                config.xd = { enable: false };
            })(window["adrum-config"] || (window["adrum-config"] = {}));
        })();
    }

    useEffect(() => {
        scriptLoad()
        appD()
        scriptLang()
    }, []);

    return (
        <React.Fragment></React.Fragment>
    )
}

export default Appadrum;