import React from 'react';
import Modal from 'react-bootstrap/Modal';
import parse from "html-react-parser";


export function LibertyPopupComponent (props) {
  let utilityLabel1 = require("../../resources/resources.liberty");

    return (
      <div className={props.class} id="modalLiberty">
        <Modal show={props.showModal}
        aria-labelledby="modal-tooearly-title"
        aria-describedby="modal-body-description"
        centered id="modal-description"
        dialogClassName={props.class}
        backdrop="static"
        keyboard={false} >
           <Modal.Header className="modal-header d-block">
              <h3 className="modal-title text-center" id="modal-tooearly-title">
                {utilityLabel1.utilityLabel.temporaryPageTitle}
                </h3>
         </Modal.Header >
         <Modal.Body className="modal-body" id="modal-body-description">
           <p> {parse(utilityLabel1.utilityLabel.temporaryPageMsg1.replace('[0]', props.effectiveDate ))}</p>{" "}
          <p>{utilityLabel1.utilityLabel.temporaryPageMsg2}</p>
         </Modal.Body >
         <Modal.Footer className="eca-modal-cta-desktop-container d-none d-sm-block text-center text-md-center">
         <button href="#" title="Click this link to view frequently asked questions."
           className="btn btn-sm btn-block-0 px-5 btn-primary text-white"
           onClick={() => {  window.location.href="https://www.Wellsfargo.com/choicecardmove/"; }}
           data-dismiss="modal"
           aria-label="FAQs"
           id="btnFaq"
         >
           {utilityLabel1.utilityLabel.faqButton}
         </button>
       </Modal.Footer>
       <Modal.Footer className="modal-footer eca-modal-cta-mobile-container d-block d-sm-none">
         <div className="container-fluid mt-1">
          <div className ="row">
           <div className="col-12 text-center">
           <button href="#" title="Click this link to view frequently asked questions." className="btn btn-block-0 btn-primary text-white m-0"
               onClick={() => {  window.location.href="https://www.Wellsfargo.com/choicecardmove/"; }}
               data-dismiss="modal"
               id="btnFaqMob"
               aria-label="FAQs">
               {utilityLabel1.utilityLabel.faqButton}
             </button>
             </div>
           </div>
         </div>
       </Modal.Footer>
        </Modal>
        </div>
    );
}

export default LibertyPopupComponent;
