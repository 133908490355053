import axiosInstance from '../services/axios.instance';

function get(url, headers) {
    return axiosInstance(url, headers).get();
}
function post(baseUrl, endPoint, headers, payload) {
    return axiosInstance(baseUrl, headers).post(endPoint, payload);
}

function put(baseUrl, endPoint, headers, payload) {
    return axiosInstance(baseUrl, headers).put(endPoint, payload);
}

// Prefixed with underscored because delete is a reserved word in javascript
function _delete(baseUrl, headers) {
    return axiosInstance(baseUrl, headers).delete();
}

export const axiosWrapper = {
    get,
    post,
    put,
    delete: _delete
};



