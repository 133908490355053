import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import * as dataService from "../../services/data.service";
import BiltSorryComponent from "./biltsorry";
import LibertySorryComponent from "./libertysorry";
import GPCCSorryComponent from "./gpccsorry";
import RubiconSorryComponent from "./rubiconSorryComponent";
import HammerSorryComponent from "./hammersorry";
import UmbrellaSorryComponent from "./umbrellasorry";
import * as utility from "../../utilities/utils";

function SystemErrorComponent(props) {
  utility.getTitleSystemUnavailable();
  const [propIvrPhone, setpropIvrPhone] = useState("");

  const getSwitchInfo = () => {
    let switchData;
    dataService.switchInfo(props.eventID, props.productType,props.sessionID).then(switchRes => {
      if (switchRes !== null && switchRes !== undefined) {
        switchData = switchRes.data
        setpropIvrPhone(switchData.ivrPhoneNumber)
      }
    }).catch(err => {
      setpropIvrPhone('1-866-537-8424');
    })
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if(props.ivrPhone) {
      setpropIvrPhone(props.ivrPhone)
    } else {
      getSwitchInfo()
    }
  }, []);

  if(props.productType ==="rubicon"){
    return <RubiconSorryComponent />
  } else if(props.productType ==="gpcc" || props.productType ==="hcom"){
    return <GPCCSorryComponent
    {...props}propIvrPhone ={propIvrPhone}/>
  } else if(props.productType ==="attune") {
    return <HammerSorryComponent title={props.utilityLabel1.utilityLabel.hammerSorryTitle} summary={props.utilityLabel1.utilityLabel.hammerSorrySummary} />
  } else if(props.productType ==="onekey" ||  props.productType ==="onekeyplus") {
    return <UmbrellaSorryComponent {...props}propIvrPhone ={propIvrPhone} />
  }
  else{
    return (
      <div>
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-title text-center text-divider-yellow text-divider-yellow-md-0 pb-2 pb-md-0 pt-0 pt-sm-1" id="eca-confirm-title">
              {parse(props.utilityLabel1.utilityLabel.switchoffTitle)}
            </h1>
          </div>
        </div>
        <div className="row eca-container">
          <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <div className="card eca-terminate-container border-0 shadow-0 shadow-md">
            <a name="eca-page-top"></a>
            <div className="card-body border-top-0 mb-0 p-md-5">
            <div className="row m-0 eca-terminate-summary-container">
              <div className="col-12 mt-md-3">
              {props?.productType === "bilt" ?
                  <BiltSorryComponent
                  {...props}propIvrPhone ={propIvrPhone}/>
                  :  <LibertySorryComponent
                  {...props}propIvrPhone ={propIvrPhone}/>
                }
                <p className="eca-switchoff-summary3">
                  {parse(props.utilityLabel1.utilityLabel.switchoffSummary3)}
                </p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default SystemErrorComponent;
