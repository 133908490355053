import parse from "html-react-parser";
import React from "react";

function RubiconFraudComponent(props) {
    return (
        <div className="row eca-terminate-fraud-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-system-title text-center pb-2 pb-md-0 pt-0 pt-sm-1" id="eca-confirm-title">
            {parse(props.utilityLabel1.utilityLabel.terminateTitle)}
            </h1>
              <p className="eca-terminate-system-summary1 text-left text-md-center pt-3">
                {parse(props.utilityLabel1.utilityLabel.terminateSummary1)}
                </p>
                <p className="eca-terminate-system-summary1 text-left text-md-center pt-2">
                {parse(props.utilityLabel1.utilityLabel.terminateSummary2)}
                </p>
                <span className="logo">
                  <img
                    id="imgIconPhone"
                    src={
                      require("../../global/img/wf/wf_icon_phone_landline_wf_gray_1.png")

                    }
                    alt=""
                    className="img-fluid mt-3"
                    style={{ width: "100%", maxWidth: "40px" }}
                  />
                </span>
          </div>
        </div>
    );
}

export default RubiconFraudComponent;
