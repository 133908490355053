import React from 'react';
import PropTypes from 'prop-types';

function LoadSpinner({ visible }) {
  return (
    <div className={visible ? '' : 'displayNoneDiv'}>
      <div className="bg-load"></div>
      <div className="bg-load-spinner" alt="Please wait">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoadSpinner;

LoadSpinner.propTypes = {
  visible: PropTypes.bool.isRequired
};
