import * as utility from "../../utilities/utils";
export const formatCCNumber = (value, productType) => {
  if (productType !== "bilt" && productType !== "choice" && productType !== "rubicon" && productType !== "onekey" && productType !== "onekeyplus") {
    if (
      value.trim().slice(0, 2) === "37" ||
      value.trim().slice(0, 2) === "34"
    ) {
      const regex = /^(\d{0,4})(\d{0,6})(\d{0,5})$/g;
      const onlyNumbers = value.replace(/[^\d]/g, "");

      return onlyNumbers.replace(regex, (regex, $1, $2, $3) =>
        [$1, $2, $3].filter((group) => !!group).join(" ")
      );
    } else {
      const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
      const onlyNumbers = value.replace(/[^\d]/g, "");

      return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter((group) => !!group).join(" ")
      );
    }
  } else {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, "");

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(" ")
    );
  }
};

export const formatMaskedCCNumber = (value, productType,actualCCNumber) => {
  if (productType !== "bilt" && productType !== "choice" && productType !== "rubicon" && productType !== "onekey" && productType !== "onekeyplus") {
    if (
      actualCCNumber.slice(0, 2) === "37" ||
      actualCCNumber.slice(0, 2) === "34"
    ) {
      const regex = /^(●{0,4})(●{0,6})(●{0,5})$/g;
      const onlyNumbers = value.replace(/[^●]/g, "");
      return onlyNumbers.replace(regex, (regex, $1, $2, $3) =>
        [$1, $2, $3].filter((group) => !!group).join(" ")
      );
    } else {
      const regex = /^(●{0,4})(●{0,4})(●{0,4})(●{0,4})$/g;
      const onlyNumbers = value.replace(/[^●]/g, "");
      return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
        [$1, $2, $3, $4].filter((group) => !!group).join(" ")
      );
    }
  } else {
    const regex = /^(●{0,4})(●{0,4})(●{0,4})(●{0,4})$/g;
    const onlyNumbers = value.replace(/[^●]/g, "");
    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(" ")
    );
  }
};


export const extractCardModel = (value, language, productType) => {
  let utilityLabels = utility.findUtilityLabelFromProduct(productType, language);
  if (productType !== "bilt" && productType !== "choice"&& productType !== "rubicon" && productType !== "onekey" && productType !== "onekeyplus") {
    if (value.slice(0, 2) === "37" || value.slice(0, 2) === "34") {
      return {
        cardType: "amex",
        ccImgPath: utilityLabels.utilityLabel.ccAmexImgPath,
        ccMaxLength: 15,
        cvvMaxLen: 4,
        cvvMsgTxt: utilityLabels.utilityLabel.lblCVVAmexMsgContent,
        cvvLabel: utilityLabels.utilityLabel.inputLabelCVVAmex,
        cvvFusionLabel: utilityLabels.utilityLabel.inputLabelCVVNonAmex1,
        errTxtCVV: utilityLabels.utilityLabel.errValidationCVVAmex,
        formattedNumber: formatCCNumber(value),
        maskedCCNumber: "#### ###### #####",
        imgCaption: utilityLabels.utilityLabel.imgAmexCaption,
      };
    } else {
      return {
        cardType: "nonamex",
        ccImgPath: utilityLabels.utilityLabel.ccNonAmexImgPath,

        ccMaxLength: 16,
        cvvMaxLen: 3,
        cvvMsgTxt: utilityLabels.utilityLabel.lblCVVNonAmexMsgContent,
        cvvLabel: utilityLabels.utilityLabel.inputLabelCVVNonAmex,
        cvvFusionLabel: utilityLabels.utilityLabel.inputLabelCVVNonAmex1,
        errTxtCVV: utilityLabels.utilityLabel.errValidationCVVNonAmex,
        formattedNumber: formatCCNumber(value),
        maskedCCNumber: "#### #### #### ####",
        imgCaption: utilityLabels.utilityLabel.imgNonAMexCaption,
      };
    }
  }  else {
    return {
      cardType: "nonamex",
      ccImgPath: utilityLabels.utilityLabel.ccNonAmexImgPath,

      ccMaxLength: 16,
      cvvMaxLen: 3,
      cvvMsgTxt: utilityLabels.utilityLabel.lblCVVNonAmexMsgContent,
      cvvLabel: utilityLabels.utilityLabel.inputLabelCVVNonAmex,
      cvvFusionLabel: utilityLabels.utilityLabel.inputLabelCVVNonAmex1,
      errTxtCVV: utilityLabels.utilityLabel.errValidationCVVNonAmex,
      formattedNumber: formatCCNumber(value),
      maskedCCNumber: "#### #### #### ####",
      imgCaption: utilityLabels.utilityLabel.imgNonAMexCaption,
    };
  }
};

export const isCCValid = (value, maxLength) => {
  if (value.replace(/\s/g, "").length === 0 || value.replace(/\s/g, "").length !== maxLength) {
    return false;
  } else {
    return true;
  }
};
