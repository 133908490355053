import React from 'react';
import * as utility from "../../../utilities/utils";
import RubiconHeader from './rubicon.header';
import CASAHeader from './casa.header';
import GPCCHeader from './gpcc.header';
import LibertyHeader from './liberty.header';
import UmbrellaHeader from './umbrella.header';

function Header(props) {
    let params = utility.getParams();
    let prodType = params.partner;
    if (prodType === "rubicon") {
        prodType = "gpcc";
    } else if(params.lob === "business-cards") {
        prodType = "rubicon";
    }
    switch (prodType) {
        case "bilt":
            return  <CASAHeader></CASAHeader>

        case "choice":
            return  <LibertyHeader></LibertyHeader>

        case "rubicon":
            return  <RubiconHeader></RubiconHeader>

        case "onekey":
        case "onekeyplus":
            return  <UmbrellaHeader></UmbrellaHeader>

        default:
            return <GPCCHeader prodType={prodType}></GPCCHeader>

    }

}

export default Header;
