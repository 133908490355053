import React, { useEffect, useState } from "react";
import Footer from "../shared/controls/footer";
import Header from "../shared/controls/header";
import parse from "html-react-parser";
import { trackEvent } from "../../services/data.service";
import BiltThankyouComponent from "./biltthankyou"
import LibertyThankyouComponent from "./libertythankyou"
import RubiconThankyouComponent from "./rubiconthankyouComponent";
import HcomThankyouComponent from "./hcomthankyouComponent";
import AccessCardThankyouComponent from "./accesscardthankyouComponent";
import UmbrellaThankyouComponent from "./umbrellathankyouComponent";
import * as utility from "../../utilities/utils";
import { Helmet, HelmetProvider } from 'react-helmet-async';

function ThankyouPage(props) {
  utility.getTitleConfirmationSuccess();

  const detailData = props.history.location.detail;

  let utilityLabel1 =  utility.findUtilityLabelFromProduct(detailData.productType, detailData.language);

  const [sessionID, setSessionID] = useState("");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setSessionID(detailData.sessionID);
  }, []);

const getSignOn=(event)=>{
  if (event === "SignOn") {
    window.location.replace(utilityLabel1.utilityLabel.hrefThankyouSignOn);
  } else if (event === "Enroll") {
    window.location.replace(utilityLabel1.utilityLabel.hrefEnroll);
  }
}
  const trackEvents = (page, event) => {
    getSignOn(event);
    if(sessionID !== null && sessionID !== "") {
      trackEvent( page, event,  sessionID).then();
    }
  };

    if(detailData.productType ==="rubicon"){
      return <RubiconThankyouComponent />
    }else if (detailData.productType ==="gpcc" || detailData.productType === undefined){
      return <AccessCardThankyouComponent  deepLinkURL={detailData.bestAction} deepLinkURLLabel={detailData.bestActionLabel} sessionID={sessionID}/>
    }
    else if (detailData.productType ==="hcom"){
      return <HcomThankyouComponent   last4DigitCC ={detailData.last4DigitCC} sessionID={sessionID}/>
    }
    else if (detailData.productType ==="onekey" || detailData.productType ==="onekeyplus" ){
      return <UmbrellaThankyouComponent language={detailData.language} sessionID={sessionID}/>
    }
    else{
      return (
 <HelmetProvider>
    <>
      <Helmet>
        <html lang={detailData.language} id="html-tag"></html>
      </Helmet>
       <div className="d-flex flex-column h-100">
     <Header />
      <main className="main-wrapper flex-shrink-0 subtitle-0">
        <div className="container my-3">
          <div className="row pagetitle-container">
            <div className="col-12 text-center pb-2 pb-md-4">
              <h1 className="eca-confirm-title text-center text-divider-yellow text-divider-yellow-md-0 pb-2 pb-md-0 pt-0 pt-sm-1" id="eca-confirm-title">
                {parse(utilityLabel1.utilityLabel.thankyouHeader)}
              </h1>
            </div>
          </div>

          <div className="row eca-container">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div className="card eca-thankyou-container border-0 shadow-0 shadow-md">
                <a name="eca-confirm-page-validation"></a>
                <div className="card-body border-top-0 mb-0 p-md-5">
                  <div className="row">
                    <div
                      className={
                        detailData.productType === "bilt" || detailData.productType === "choice"
                          ? "col-12 col-sm-3 text-center order-first order-md-last"
                          : "col-12 col-sm-3 order-sm-last text-center"
                      }
                    >
                      <img
                        src={utilityLabel1.utilityLabel.accountSummarySmall}
                        className="img-fluid" id="imgThankyou"
                        style={{ width: "100%", maxWidth: "125px" }}
                        alt="" aria-hidden="true"
                      />
                    </div>
                    <div
                      className={
                        detailData.productType === "bilt" || detailData.productType === "choice"
                          ? "col-12 col-sm-9 order-last order-md-first pt-3"
                          : "col-12 col-sm-9"
                      }
                    >
                      <p className="eca-confirm-summary1">
                        {parse(utilityLabel1.utilityLabel.thankyouPageMessage1)}{" "}
                        <span className="eca-confirm-card-last4">
                          {detailData.last4DigitCC}
                        </span>{" "}
                        {utilityLabel1.utilityLabel.thankyouPageMessage2}
                      </p>
                      <p className="eca-confirm-advice">
                        {parse(
                          utilityLabel1.utilityLabel
                            .thankyouConfirmAdviseMessage
                        )}
                      </p>
                    </div>
                  </div>
                  <hr className="divider-line d-none d-md-block" />
                  <div className="row mt-4">
                    <div className="col-3 col-sm-2 text-center">
                      <img
                        src={utilityLabel1.utilityLabel.CConboardingpreferences}
                        alt="" aria-hidden="true" id="imgThankyouPage"
                        className="img-fluid"
                        style={{ width: "100%", maxWidth: "75px" }}
                      />
                    </div>
                    <div className="col-9 col-sm-10 pt-2">
                    {detailData.productType === "bilt"? <>
                    <BiltThankyouComponent
                       utilityLabel1={utilityLabel1}
                       trackEvents={trackEvents} />
                    </> :
                       <LibertyThankyouComponent
                       utilityLabel1={utilityLabel1}
                       trackEvents={trackEvents} />
                     }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer
        language={detailData.language}
        productType={detailData.productType}
      />
    </div>
</>
    </HelmetProvider>
    );
  }
}

export default ThankyouPage;