import React from "react";
import parse from "html-react-parser";

export function CasaModalComponent(props){
  let utilityLabel1 = require("../../resources/resources.casa");
    return (
      <div className="row">
      <div className="col-12">
        <p className="modal-eca-alternativehelp-primary-desc">
          {utilityLabel1.utilityLabel.cancelMsg1}
        </p>
        <p className="modal-eca-alternativehelp-secondary-desc">
          {utilityLabel1.utilityLabel.cancelMsg2}
        </p>
        <p className="modal-eca-alternativehelp-desc1">
        {parse(utilityLabel1.utilityLabel.cancelMsgContent)}
        {<React.Fragment>
                <a href={utilityLabel1.utilityLabel.hrefEnroll}
                  id="enroll"
                  onClick={() => props.trackEvents("Cancel", "Enroll",props.sessionID)}
                  className="underlined"
                  title="Click this link to navigate Enroll page."
                >
                  {utilityLabel1.utilityLabel.enrollNowThankyou}
                </a>{" "}
                {utilityLabel1.utilityLabel.or}
                <a href={utilityLabel1.utilityLabel.hrefSignOn}
                  id="signOn"
                  onClick={() => props.trackEvents("Cancel", "SignOn",props.sessionID)}
                  className="underlined"
                  title="Click this link to navigate SignOn page."
                >
                  {utilityLabel1.utilityLabel.signOnSorry}{"."}
                </a>
                </React.Fragment>}
                </p>
                {
                  <React.Fragment>
                  <p className="eca-switchoff-summary2">
                    {parse(utilityLabel1.utilityLabel.switchoffSummary2)}
                    <span style={{ whiteSpace: "nowrap" }}>
                      <a
                        href={
                          utilityLabel1.utilityLabel.switchoffPhoneHref
                        }
                        id="IVRPhone"
                        onClick={(e) =>
                          props?.trackEvents("IVRSorry", "IVRPhone", props.sessionID)
                        }
                        className="underlined"
                        title={"Call "+props.ivrPhoneNumber}
                        aria-label={"Call "+props.ivrPhoneNumber}
                      >
                        <u>{props.ivrPhoneNumber}</u>
                      </a>
                      .
                    </span>
                  </p>
                  </React.Fragment>
                }
                 </div>
          </div>
            )

}

export default CasaModalComponent;
