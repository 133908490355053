import React from "react";
import { WarningMessageComponent } from "../shared/controls/warningMessage.component";
import SessionTimeoutComponent from "../../SessionTimeout.component";
import LoadSpinner from "../shared/loader/load-spinner";
import parse from "html-react-parser";
import Appadrum from "../adrum.component/appAdrum";
import * as utility from "../../utilities/utils";
import CardConfirmLanding from '../landing.component/cardconfirm-component';
export default class FusionLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      utilityLabel1: require("../resources/resources.en"),
      isLoading: false,
      warningMsg: "",
      displayWarningMsg: false,
      sessionTimeoutDisplay: false,
      productType:''
    };
    this.params = utility.getParams();
  }

  componentDidMount() {
    utility.getTitleConfirmcard();
    const queryString = require("query-string");
    let productType = utility.getPartner();
    const language = this.props.history.location.data
      ? this.props.history.location.data.language
      : queryString.parse(location.search).language;
    let utilityLabel1 = utility.findUtilityLabelFromProduct(productType, language);

    this.setState({
      sessionID: this.props.sessionID,
      eventID: this.props.eventID,
      language: language || utility.getQString(),
      entryPoint: utility.getEntryType(),
      utilityLabel1,
      productType: productType,
      warningMsg :utilityLabel1.utilityLabel.warningMsgforFailedAttempt
    }, () => {
    });
  }

  setWarningMessage = (child) => {
    this.setState(
      {
        displayWarningMsg: child.displayWarningMsg,
        warningMsg: this.state.utilityLabel1.utilityLabel.ecaErrorMessage1,
      },
      () => {
        if (this.state.displayWarningMsg) {
          this.setState({
            cvv: "",
            ccNumber: "",
            maskedCCNumber: "",
            maskedCVV: "",
            DOB: "",
            ssn: "",
            ccValid: true,
            myCcNumber:"",
            sessionTimeoutDisplay: false,
            formErrors: {
              ccNumber: "",
              cvv: "",
              ssn: "",
              dob: "",
            },
          });
        }
      }
    );
  };

  callbackTogetChildData = (isLoading ,isWarning ,isSessionTimeout) => {
    this.setState({
      isLoading : isLoading,
      displayWarningMsg: isWarning,
      sessionTimeoutDisplay: isSessionTimeout
    });
  }

  render() {
    return (
      <div className="d-flex flex-column h-100">
        <Appadrum></Appadrum>
        <SessionTimeoutComponent
          modelClose={() => {
            if (this.state.sessionTimeoutDisplay) {
              window.location.reload()
            }
          }}
          callbackParent={this.setWarningMessage}
          handleCancelModalClose={this.handleCancelModalClose}
          language={this.state.language}
          sessionTimeoutDisplay={this.state.sessionTimeoutDisplay}
          pageLocation="landingPage"
          eventID={this.props.eventID}
          sessionID={this.state.sessionID}
        />
        <main className="main-wrapper flex-shrink-0 subtitle-0">
          <div className="container  my-3">
            <div
              className={
                this.state.displayWarningMsg
                  ? "row pagelevelmessage-container"
                  : "displayNoneDiv"
              }
            >
              <WarningMessageComponent
                ecaErrorMessage1={this.state.warningMsg}
              />
            </div>

            <div className="row pagetitle-container">
              <div className="col-12 text-center pb-2 pb-md-1">
                <h1 className="eca-confirm-title text-center pb-2 pb-md-3 pb-lg-0 pt-0 pt-sm-1" id="eca-confirm-title">
                {parse(this.state.utilityLabel1.utilityLabel.lblFusionConfirm)}
                </h1>
              </div>
            </div>
            <div className="row eca-container">
              <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3">
                <div className="row m-0">
                  <div className="col-12 mt-md-3 px-1">
                    <p className="eca-confirm-summary">
                    {parse(this.state.utilityLabel1.utilityLabel.lblFusionConfirmContent)}</p>
                  </div>
                </div>
           {this.state.productType!=='' &&

             <CardConfirmLanding language={this.state.language}
                                 ivrPhoneNumber={this.state.ivrPhoneNumber} entryPoint={this.state.entryPoint}
                                 {...this.props} productType={this.state.productType} sessionID={this.state.sessionID}
                                 ecaErrorMessage1={this.state.warningMsg} sessionTimeoutDisplay={this.state.sessionTimeoutDisplay}
                                 callbackTogetChildData={this.callbackTogetChildData}/>}
              </div>
            </div>
            <LoadSpinner visible={this.state.isLoading} />
          </div>
        </main>
      </div>
    );
  }
}


