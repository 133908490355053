import parse from "html-react-parser";
import React from "react";

function LibertyThankyouComponent(props) {
  return (
   <React.Fragment>
    <p className="eca-confirm-summary2">{parse(props.utilityLabel1.utilityLabel.thankyouPageMessage3)}</p>
      <p className="eca-confirm-summary3">
        <a href={props.utilityLabel1.utilityLabel.hrefEnroll}
          className="underlined"
          id="btnChoiceenroll"
          onClick={(e) => { props.trackEvents("ThankYou", "Enroll") }}
          title="Click this link to navigate Enroll page."
        >
          {props.utilityLabel1.utilityLabel.enrollNowThankyou}
        </a>{" "}
        {props.utilityLabel1.utilityLabel.or}{" "}
        <a href={props.utilityLabel1.utilityLabel.hrefThankyouSignOn}
          className="underlined"
          id="btnChoicesignOn"
          onClick={(e) => { props.trackEvents("ThankYou", "SignOn") }}

          title="Click this link to navigate SignOn page."

        >
          {props.utilityLabel1.utilityLabel.signOnSorry}
        </a>{" "}
        {parse(props.utilityLabel1.utilityLabel.thankyouPageMessage4)}
      </p>
      </React.Fragment>
  );
}

export default LibertyThankyouComponent;
