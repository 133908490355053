import React, { useEffect} from 'react';
import parse from "html-react-parser";
import cardImage from "../../global/img/wf/eca-hammer-landing.png";
import * as utility from "../../utilities/utils";
import { ServiceApi } from '../../environment';


function AttuneConfirmLandingComponent(props) {
  utility.getTitleConfirmcard();
  let page = (utility.getFlowInd() === "Q") ?  "OLB QR" : "OLB";

  useEffect(() => {
    utility.trackEventsForNoredirect(page, "OLBAttuneLanding", props.sessionID);
  }, []);
  let params = utility.getParams();
  let utilityLabel1 ='';
  if(params.language === "es"){
     utilityLabel1 = require("../resources/resources.es");
  }else{
    utilityLabel1 = require("../resources/resources.en");
  }

  return (

    <main className="main-wrapper flex-shrink-0 subtitle-0" id="attuneConfirmPage">
      <div className="container my-3" >
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-5 pb-md-4">
            <h1 className="eca-confirm-title text-center pb-3 pt-md-3" id="eca-confirm-title" style={{ fontSize:"40px" }}>
              {parse(utilityLabel1.utilityLabel.lblAttuneConfirm)}
            </h1>
              <img
                id="imgAttuneCard"
                src={cardImage}
                 alt=""
                className="img-fluid d-none d-sm-inline"
                style={{ width: "100%", maxWidth: "327px" }}
              />
              <img
                id="imgCardAttune"
                src={cardImage}
                 alt=""
                className="img-fluid d-inline d-sm-none"
                style={{ width: "100%", maxWidth: "184px" }}
              />
          </div>
        </div>
        <div className="row eca-container pb-4 pt-md-4">
          <div className="col-12 text-center">
            <a style={{ minWidth: "184px"}} type="button" role="button" className="btn btn-consumer m-0 mb-5 mb-md-3 w-auto d-inline-block eca-btn-signon text-white"
              onClick={()=>{utility.trackEventsForRedirect(page,"SignOn",props.sessionID,(params.language==="es"?
              ServiceApi.OLB_SIGN_ON_SPN_URL:ServiceApi.OLB_SIGN_ON_ENG_URL))}}
              id="btnSignOnToConfirm"
              tabIndex="0"
            >
              {utilityLabel1.utilityLabel.signOntoConfirm}
            </a>
          </div>
          <div className="col-12 text-center">
            <p className="mb-0" id="labelNoOnlineAct"> {parse(utilityLabel1.utilityLabel.noOnlineAccount)}</p>
            <a type="button" role="button" id="btnEnrollToConfirm" className="btn btn-secondary m-0 w-auto d-inline-block eca-btn-enroll mt-0"
              style={{ minWidth: "184px", width: "auto", display: "inline-block" }}
              onClick={()=>{utility.trackEventsForRedirect(page,"Enroll",props.sessionID,
                (params.language ==="es"?ServiceApi.OLB_ENROLL_SPN_URL:ServiceApi.OLB_ENROLL_ENG_URL))}}
              tabIndex="0" >
              {utilityLabel1.utilityLabel.enrolltoConfirm}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}


export default AttuneConfirmLandingComponent;
