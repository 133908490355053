import React,  { useEffect} from 'react';
import parse from "html-react-parser";
import * as utility from "../../utilities/utils";
import { ServiceApi } from '../../environment';
import cardImage from "../../global/img/wf/eca-hammer-landing.png";


function  OLBConfirmLandingComponent(props)  {
  utility.getOLBTitleConfirmcard();

  let params = utility.getParams();

  let page = "OLB";

  let utilityLabel1 ='';
  if(params.language === "es"){
     utilityLabel1 = require("../resources/resources.es");
  }else{
    utilityLabel1 = require("../resources/resources.en");
  }


  if (utility.getFlowInd() === "Q") {
    page = page + " QR";
  }

  useEffect(() => {
    utility.trackEventsForNoredirect(page, "OLBLanding", props.sessionID);

  }, []);
return (
  <main className="main-wrapper flex-shrink-0 subtitle-0" id="olbConfirmPage">
      <div className="container my-3" >
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-5 pb-md-4">
                  <img
                    id="imgOlbCard"
                src={cardImage}
                alt=""
                className="img-fluid d-none d-sm-inline"
                style={{ width: "100%", maxWidth: "327px" }}
                  />
              <img
                id="imgCard"
                src={cardImage}
                alt=""
                className="img-fluid d-inline d-sm-none"
                style={{ width: "100%", maxWidth: "184px" }}
              />
            <h1 className="eca-confirm-title text-center text-400 pb-2 pt-md-3" id="eca-confirm-title" style={{ fontSize:"40px" }}>
            {parse(utilityLabel1.utilityLabel.lblOlbConfirm)}
            </h1>
            <h2 className="eca-confirm-body text-center">{parse(utilityLabel1.utilityLabel.lblOlbConfirmLandingMsg1)}</h2>
          </div>
        </div>
        <div className="row eca-container pb-5">
          <div className="col-12 text-center">
          <a
          style={{ minWidth: "184px"}}
           type="button" role="button" className="btn btn-consumer m-0 mb-5 mb-md-4 w-auto d-inline-block eca-btn-signon text-white"
           onClick={() => { utility.trackEventsForRedirect("OLB", "SignOn", props.sessionID,(params.language==="es"?
           ServiceApi.OLB_SIGN_ON_SPN_URL:ServiceApi.OLB_SIGN_ON_ENG_URL)) }}
           id="btnOLBSignOn"
           tabIndex="0"
         >
         {utilityLabel1.utilityLabel.signOnConfirm}
         </a>
          </div>
          <div className="col-12 text-center">
          <p className="mb-1">{parse(utilityLabel1.utilityLabel.lblOlbConfirmLandingMsg2)}</p>
          <a className="eca-btn-confirm" role="button" id ="btnConfirmFewDetails"
          style={{minWidth: "184px"}}
          onClick={props.handleOnButtonClick}
          tabIndex="0"><strong>{utilityLabel1.utilityLabel.confirmWithFewDetails}</strong></a>
          </div>
        </div>
      </div>
      </main>
    );
}


export default OLBConfirmLandingComponent;
