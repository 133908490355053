import React, {useEffect, useRef} from "react";
import ReactModal from 'react-modal';
import history from "../../../history";
import CasaModalComponent from "./casaModal.component.js";
import LibertyModalComponent from "./libertyModal.component.js";
import GpccModalComponent from "./gpccModal.component.js";
import RubiconModalComponent from "./rubiconModal.component";
import OnekeyModalComponent from "./onekeyModal.component";
import * as utility from '../../../utilities/utils';
import useEscapeKey from "./useEscapeKey";
function Header(props) {
  const buttonRef = useRef(null);
  let utilityLabel1 = getResource(props);
  useEffect(()=>{   
    buttonRef.current?.focus();
}, [])
  return (
    <div className="m-0 d-block modal-header" >
    <div id="mobile-consent-dailog">
    <button 
      ref={buttonRef} 
      id="btnClose" 
      className="btn btn-sm btn-sm-p-0 btn-sm-m-0 title-close" 
      aria-label={utilityLabel1.utilityLabel.closeDialog} 
      onClick={() => props.handleClose("X")}>
        X
    </button>
    <h2 className="modal-title font-sans" id="modal-pinpad-title">
    { ( props.productType !== 'bilt' && props.productType !=='choice'  ) && props.title}
    </h2>
    </div>
    </div>
  );
}

function Footer(props) {
  const lang = props.language;
  const entryPoint = props.entryPoint;
  const sessionID = props.sessionID;
  let utilityLabel1 = getResource(props)


  const onClickCancelExit = () => {
    props?.trackEvents("Cancel", "CancelExit", sessionID);

    let productTypeUrl = `/?language=${lang}&entrypoint=${entryPoint}&page=sorry&partner=${utility.getPartner()}`;
    if (utility.getParams().lob === "business-cards") {
      productTypeUrl = `/?language=${lang}&entrypoint=${entryPoint}&page=sorry&lob=business-cards`
    }
    history.push({
      pathname: props.productType
        ? productTypeUrl
        : `/?language=${lang}&entrypoint=${entryPoint}&page=sorry`,
      detail: {
        language: props.language,
        type: "UNKNOWN",
        entryPoint,
        sessionID:props.sessionID,
        productType: props.productType,
        eventID: props.eventID
      },
    });
  };
  let goBackBtn = "";
  if (props.isFusionStyle && lang === "es") {
    goBackBtn = utilityLabel1.utilityLabel.fusiongoBackBtn;
  } else if (props.productType === "onekey" || props.productType ==='onekeyplus') {
    goBackBtn = utilityLabel1.utilityLabel.onekeygoBackBtn;
  } else {
    goBackBtn = utilityLabel1.utilityLabel.goBackBtn;
  }

  let cancelExitBtn = "";
  if (props.isFusionStyle && lang === "es") {
    cancelExitBtn = utilityLabel1.utilityLabel.fusioncancelExitBtn;
  } else if (props.productType === "onekey" || props.productType ==='onekeyplus') {
    cancelExitBtn = utilityLabel1.utilityLabel.onekeyCancelAndExit;
  } else {
    cancelExitBtn = utilityLabel1.utilityLabel.cancelExitBtn;
  }


  return (
    <>
      <div className="eca-modal-cta-desktop-container d-none d-sm-block text-center text-md-right">
        <button
          id="btnNoGoback"
          type="button"
          className="btn btn-sm px-sm-5 mr-sm-0 btn-outline-secondary"
          onClick={() => props.onClick("Back")}
          data-dismiss="modal"
          title="Go back"
          aria-label={goBackBtn}
        >
           {goBackBtn}

        </button>
        <button
          type="button"
          className="btn btn-sm px-sm-4 btn-primary modal-eca-alternativehelp-cancelexit"
          data-dismiss="modal"
          title="Cancel and Exit"
          aria-label={cancelExitBtn}
          onClick={onClickCancelExit}
          id="btnYesCancel"
        >
          {cancelExitBtn}
        </button>
      </div>

      <div className="modal-footer eca-modal-cta-mobile-container d-block d-sm-none">
        <div className="row m-0">
          <div className="col-6 pl-0 pr-1">
            <button
              type="button"
              id="backMobile"
              className="btn btn-block btn-sm mx-0 btn-outline-secondary"
              onClick={() => props.onClick("Back")}
              data-dismiss="modal"
              title="Go back"
              aria-label={goBackBtn}
            >
              {goBackBtn}
            </button>
          </div>
          <div className="col-6 px-0">
            <button
              type="button"
              className="btn btn-block btn-sm mx-0 btn-primary modal-eca-alternativehelp-cancelexit"
              data-dismiss="modal"
              title="Cancel and Exit"
              aria-label={cancelExitBtn}
              onClick={onClickCancelExit}
            >
              {cancelExitBtn}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export function getResource(props){
let casaResource = require("../../resources/resources.casa")
let libertyResource = require("../../resources/resources.liberty")
let rubiconResource = require("../../resources/resources.rubicon")
let esResource = require("../../resources/resources.es")
let enResource = require("../../resources/resources.en")

if(props.productType === "bilt"){
   return casaResource;
}else if(props.productType === "choice"){
    return libertyResource;
}else if(props.productType === "rubicon"){
     return rubiconResource;
 }else if(props.language === "es" ){
      return esResource;
 }else{
  return enResource;
 }

}

export function ReactModalComponent(props) {

  let utilityLabel1 = getResource(props)
  useEscapeKey(() => props.handleClose("X"));
  return (
    <div className={props.class} >
      <ReactModal
        isOpen={props.show}
        onHide={() => props.handleClose("X")}
        dialogClassName={props.class}
        backdrop="static"
        keyboard={false}
        aria-labelledby={props.productType !== 'bilt' && props.productType !== 'choice'?"modal-pinpad-title":""}
        aria-label={props.productType === 'bilt' || props.productType === 'choice'?"modal-cancel":""}
        ariaHideApp={false}
        id="modal-comp"
        className="modal-dialog"
      >
      <div className="modal-content">
        <Header
         handleClose = {props.handleClose}
         productType = {props.productType}
         language = {props.language}
         title = {props.productType ==='onekey'||props.productType ==='onekeyplus'?utilityLabel1.utilityLabel.onekeycancelTitle:utilityLabel1.utilityLabel.cancelTitle}/>
        <div className="modal-body">
          {props.productType === 'bilt'
          ?<CasaModalComponent {...props}trackEvents={props.trackEvents}></CasaModalComponent>
          :props.productType ==='choice'
          ?<LibertyModalComponent trackEvents={props.trackEvents}></LibertyModalComponent>
          :props.productType ==='rubicon'
          ?<RubiconModalComponent trackEvents={props.trackEvents}></RubiconModalComponent>
          :(props.productType ==='onekey'||props.productType ==='onekeyplus')
          ?<OnekeyModalComponent {...props}></OnekeyModalComponent>
          :<GpccModalComponent isFusionStyle = {props.isFusionStyle} trackEvents={props.trackEvents} productType={props.productType}></GpccModalComponent>
        }
        </div>
        <Footer
          buttonText={props.buttonText}
          onClick={props.handleClose}
          language={props.language}
          entryPoint={props.entryPoint}
          trackEvents={props.trackEvents}
          sessionID={props.sessionID}
          productType={props.productType}
          {...props}
        ></Footer>
        </div>
      </ReactModal>
    </div>
  );
}

export default ReactModalComponent;
