import React from "react";
import { WarningMessageComponent } from "../shared/controls/warningMessage.component";
import SessionTimeoutComponent from "../../SessionTimeout.component";
import LoadSpinner from "../shared/loader/load-spinner";
import parse from "html-react-parser";
import Appadrum from "../adrum.component/appAdrum";
import * as utility from "../../utilities/utils";
import CardConfirmLanding from '../landing.component/cardconfirm-component';

var ndspmd = "0.0.0.0";
var timepOut;

export default class ShortLandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      utilityLabel1: require("../resources/resources.en"),
      isLoading: false,
      warningMsg: "",
      displayWarningMsg: false,
      sessionTimeoutDisplay:false,
      productType:""
    };

    this.params = utility.getParams();
  }
  componentDidMount() {
    utility.getTitleConfirmcard();
    const queryString = require("query-string");
    let productType = utility.getPartner();
    this.setState({productType : productType})
    const language = this.props.history.location.data
      ? this.props.history.location.data.language
      : queryString.parse(location.search).language;
    let utilityLabel1 = utility.findUtilityLabelFromProduct(productType, language);
    this.setState({
      sessionID: this.props.sessionID,
      eventID: this.props.eventID,
      language: language || utility.getQString(),
      entryPoint: utility.getEntryType(),
      utilityLabel1,
      displayWarningMsg: false,
      productType: productType,
      warningMsg :utilityLabel1.utilityLabel.warningMsgforFailedAttempt
    });
  }
  setWarningMessage = (child) => {
    this.setState(
      {
        displayWarningMsg: child.displayWarningMsg,
        warningMsg: this.state.utilityLabel1.utilityLabel.ecaErrorMessage1,
      },
      () => {
        if (this.state.displayWarningMsg) {
          this.setState({
            cvv: "",
            ccNumber: "",
            maskedCCNumber: "",
            maskedCVV: "",
            DOB: "",
            ssn: "",
            ccValid: true,
            myCcNumber:"",
            sessionTimeoutDisplay: false,
            formErrors: {
              ccNumber: "",
              cvv: "",
              ssn: "",
              dob: "",
            },
          });
        }
      }
    );
  };
  callbackTogetChildData = (isLoading ,isWarning,isSessionTimeout) => {
    this.setState({
      isLoading: isLoading,
      displayWarningMsg: isWarning,
      sessionTimeoutDisplay: isSessionTimeout
    });
  }
  render() {
    return (
      <div className="d-flex flex-column h-100">
        <Appadrum></Appadrum>
        <SessionTimeoutComponent
          modelClose={() => {
            if (this.state.sessionTimeoutDisplay) {
              window.location.reload()
            }
          }}
          callbackParent={this.setWarningMessage}
          handleCancelModalClose={this.handleCancelModalClose}
          language={this.state.language}
          sessionTimeoutDisplay={this.state.sessionTimeoutDisplay}
          pageLocation="landingPage"
          eventID={this.props.eventID}
          sessionID={this.state.sessionID}
        />
        <main className="main-wrapper subtitle-0">
          <div className="container  my-3">
            <div
              className={
                this.state.displayWarningMsg
                  ? "row pagelevelmessage-container"
                  : "displayNoneDiv"
              }
            >
              <WarningMessageComponent
                ecaErrorMessage1={this.state.warningMsg}
              />
            </div>

            <div className="row pagetitle-container">
              <div className="col-12 text-center pb-5 pb-md-4">
                <h1 className="eca-confirm-title text-center text-divider-yellow text-divider-yellow-lg-0 pb-2 pb-md-3 pb-lg-0  pt-0 pt-sm-1" id="eca-confirm-title">
                {parse(this.state.utilityLabel1.utilityLabel.lblConfirm)}
                </h1>
              </div>
            </div>

            <div className="row eca-container">
              <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                <div className="card eca-landing-container border-0 shadow-0 shadow-md">
                  <a name="eca-confirm-page-validation"></a>
                  <div className="card-body border-top-0 mb-0">
                    <div className="row m-0">
                      <div className="col-12 mt-md-3">
                        <p className="eca-confirm-summary">
                          {" "}
                          {parse(
                            this.state.utilityLabel1.utilityLabel
                              .lblConfirmContent
                          )}
                        </p>
                        <p
                          className="eca-confirm-reqfield"
                          style={{ fontSize: "0.934em" }}>
                          {
                            this.state.utilityLabel1.utilityLabel
                              .lblFieldRequired
                          }
                        </p>
                      </div>
                    </div>

                    { this.state.productType!=='' &&
             
              <CardConfirmLanding language={this.state.language}  ivrPhoneNumber={this.state.ivrPhoneNumber}
                    entryPoint={this.state.entryPoint} {...this.props} productType={this.state.productType}
                    sessionID={this.state.sessionID}   ecaErrorMessage1={this.state.warningMsg}
                                  callbackTogetChildData={this.callbackTogetChildData}
                                  sessionTimeoutDisplay={this.state.sessionTimeoutDisplay}   />
                        }
           

                  </div>
                </div>
              </div>
            </div>
            <LoadSpinner visible={this.state.isLoading} />
          </div>
        </main>
      </div>
    );
  }
}
