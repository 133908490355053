import parse from "html-react-parser";
import React, { useState, useEffect} from 'react';
import Header from "../shared/controls/header";
import Footer from "../shared/controls/footer";
import * as utility from "../../utilities/utils";
import { ServiceApi } from '../../environment';
import { Helmet, HelmetProvider } from 'react-helmet-async';

function AccessCardThankyouComponent(props) {

  const [deepURLObjectVal, setdeepURLObjectVal] = useState([]);
  let params = utility.getParams();
  let utilityLabel1 ='';
  let deepURLObject = null;

  if(params.language === "es"){
     utilityLabel1 = require("../resources/resources.es");
  }else{
    utilityLabel1 = require("../resources/resources.en");
  }


  const getDeepLinks = () => {
    let deepUrlsInfo = [];
    if(params.language === "es"){
      utilityLabel1 = require("../resources/resources.es")
       deepUrlsInfo = [
        {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Personalice tarjeta",
          title: "Personalice tarjeta",
          label: "customizeCard",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Vaya a las cuentas",
          title: "Vaya a las cuentas",
          label: "goToAccounts",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Vea sus ofertas",
          title: "Vea sus ofertas",
          label: "deals",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Establezca pagos automáticos",
          title: "Establezca pagos automáticos",
          label: "autoPay",
          device: "desktop, mobile"
        },
           {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Inicie sesión para agregar un usuario",
          title: "Inicie sesión para agregar un usuario",
          label: "addUser",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Inicie sesión para actualizar ingresos",
          title: "Inicie sesión para actualizar ingresos",
          label: "updateIncome",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=SPN",
          display: "Añada tarjeta a billetera digital",
          title: "Añada tarjeta a billetera digital",
          label: "digitalWallet",
          device: "mobile"
        }
      ];
    }else{
      utilityLabel1 = require("../resources/resources.en")
      deepUrlsInfo = [
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on to customize card",
          title: "Customize card",
          label: "customizeCard",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on go to accounts",
          title: "Sign on go to accounts",
          label: "goToAccounts",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on to view my deals",
          title: "Sign on to view my deals",
          label: "deals",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on to set up Autopay",
          title: "Sign on to set up Autopay",
          label: "autoPay",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on to add user",
          title: "Sign on to add user",
          label: "addUser",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on to update income",
          title: "Sign on to update income",
          label: "updateIncome",
          device: "desktop, mobile"
        },
        {
          url: props.deepLinkURL+"&langPref=ENG",
          display: "Sign on to add to digital wallet",
          title: "Sign on to add to digital wallet",
          label: "digitalWallet",
          device: "mobile"
        }
      ];
    }
    deepURLObject = deepUrlsInfo.filter(x => x.device.includes(utility.getDeviceType()) && x.label === props.deepLinkURLLabel)
    setdeepURLObjectVal(deepURLObject[0]);
  }

  useEffect(() => {
    getDeepLinks();
  }, []);

  const getBestactionsDesktop = () => {
    if (deepURLObjectVal !== null && deepURLObjectVal !== undefined ) {
      (utility.getDeviceType()==='desktop' && deepURLObjectVal.display !== undefined )
      && utility.getDeviceType()==='desktop' && utility.trackEventsForNoredirect("Thankyou",'Presented '
        + deepURLObjectVal.display, props.sessionID, deepURLObjectVal.url)
     return (<div className="col-12 text-center" >
            <a type="button" tabIndex="0" className="btn btn-all-primary w-auto d-inline-block m-0 mb-3 eca-btn-signon2" id="desktopDeepLinkUrl"
            onClick={() => { utility.trackEventsForRedirect("Thankyou", deepURLObjectVal.display, props.sessionID, deepURLObjectVal.url) }}
            title={deepURLObjectVal.title}>{deepURLObjectVal.display}</a>
            </div>);
    }else{
      return (<div className="col-12 text-center">
        <a type="button" role="button" tabIndex="0"
           className="btn btn-all-primary w-auto d-inline-block m-0 mb-3 eca-btn-signon1"
           onClick={() => { utility.trackEventsForRedirect("Thankyou", "Sign on go to accounts", props.sessionID,
           (params.language==="es"?ServiceApi.SIGN_ON_ACCT_SPN_URL:ServiceApi.SIGN_ON_ACCT_ENG_URL))}}
           id="btnSignOnAccounts">
          {parse(utilityLabel1.utilityLabel.signOnGoToAccountsBtn)}
        </a></div>);
    }
}

const getBestactionsMobile = () => {
  if (deepURLObjectVal !== null && deepURLObjectVal !== undefined ) {
    (utility.getDeviceType() === 'mobile' && utility.trackEventsForNoredirect("Thankyou", 'Presented ' + deepURLObjectVal.display, props.sessionID, deepURLObjectVal.url))
   return (<div className="col-12 text-center" >
          <a type="button" tabIndex="0" className="btn btn-all-primary w-auto d-inline-block m-0 mb-3  eca-btn-signon2" id="mobileDeepLinkUrl"
          onClick={() => { utility.trackEventsForRedirect("Thankyou", deepURLObjectVal.display, props.sessionID, deepURLObjectVal.url) }}
          title={deepURLObjectVal.title}>{deepURLObjectVal.display}</a>
          </div>);
  }else{
    return(<div className="col-12 text-center">
      <a type="button" role="button" tabIndex="0"
         className="btn btn-all-primary w-auto d-inline-block m-0 mb-3 eca-btn-signon1"
         onClick={() => { utility.trackEventsForRedirect("Thankyou", "Sign on go to accounts",
           props.sessionID,(params.language==="es"?ServiceApi.SIGN_ON_ACCT_SPN_URL:ServiceApi.SIGN_ON_ACCT_ENG_URL))}}
         id="btnMobileSignOnAccounts"
      >{parse(utilityLabel1.utilityLabel.signOnGoToAccountsBtn)}</a>
    </div>);
  }
}
  return (
      <HelmetProvider>
            <>
              <Helmet>
                <html lang={params.language} id="html-tag"></html>
              </Helmet>
    <div className="d-flex flex-column h-100 dce-bgpage  pace-done" id="acesscardPage">
      <Header />
      <main className="main-wrapper flex-shrink-0 subtitle-0">
      <div className="container my-3">
        <div className="row pagetitle-container">
          <div className="col-12 text-center pb-2 pb-md-4">
            <span className="logo">
              <img
                id="imgIconCheckmark"
                src={
                  require("../../global/img/wf/icon_checkmark_approved.png")
                }
                 alt=""
                className="img-fluid"
                style={{ width: "100%", maxWidth: "88px" }}
              />
            </span>
            <h1 className="eca-confirm-title text-center pb-2 pb-md-0 pt-3" id="eca-confirm-title">
              {parse(utilityLabel1.utilityLabel.thankyouPage2Message1)}</h1>
            <p className="eca-confirm-summary1 text-left text-md-center pt-3">{parse(utilityLabel1.utilityLabel.thankyouPage2Message2)}</p>
            <p className="eca-confirm-summary2 text-left text-md-center">{parse(utilityLabel1.utilityLabel.thankyouPage2Message3)}
              {parse(utilityLabel1.utilityLabel.thankyouPage2Message4)}</p>
          </div>
        </div>
        <div className="row cta-container d-none d-md-block" id="desktopAcessCard">
          <div className="col-12 text-center">
            {getBestactionsDesktop()}
            <div className="col-12 text-center">
              <a
                type="button" role="button" tabIndex="0"  className="btn btn-secondary w-auto d-inline-block m-0 mb-3 eca-btn-signon6"
                onClick={() => { utility.trackEventsForRedirect("Thankyou", "Enroll in online banking", props.sessionID ,
                (params.language==="es"?ServiceApi.ENROLL_BANK_SPN_URL:ServiceApi.ENROLL_BANK_ENG_URL))}}
                id="btnEnrollNow"
              >
                {parse(utilityLabel1.utilityLabel.enrollOnlinebankingBtn)}
              </a>
            </div>
          </div>
        </div>
        <div className="row cta-container d-xs-block d-md-none" id="mobileAcessCard">
          <div className="col-12 text-center">
            {getBestactionsMobile()}
            <div className="col-12 text-center">
              <a
                type="button"role="button"  tabIndex="0"  className="btn btn-secondary w-auto d-inline-block m-0 mb-3 eca-btn-signon6"
                onClick={() => { utility.trackEventsForRedirect("Thankyou", "Enroll in online banking",
                  props.sessionID,(params.language==="es"?ServiceApi.ENROLL_BANK_SPN_URL:ServiceApi.ENROLL_BANK_ENG_URL))}}
                id="btnMobileEnrollNow"
              >
                {parse(utilityLabel1.utilityLabel.enrollOnlinebankingBtn)}
              </a>
            </div>
          </div>
        </div>

      </div>
      </main>
      <Footer />
    </div>
     </>
                  </HelmetProvider>

  );
}
export default AccessCardThankyouComponent;