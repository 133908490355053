import parse from "html-react-parser";
import React from "react";
import * as utility from "../../utilities/utils";
function UmbrellaSorryComponent(props) {
  utility.getTitleSystemUnavailable();
  return (
    <div className="row eca-terminate-system-container">
      <div className="col-12 text-center pb-2 pb-md-4">
            <h1 className="eca-terminate-system-title text-center text-400 pb-2 pb-md-0 pt-0 pt-sm-12" id="eca-confirm-title">
            {parse(props.utilityLabel1.utilityLabel.switchoffSummary6)}</h1>
            <p className="eca-terminate-system-summary1 text-left text-md-center pt-3">
              {parse(props.utilityLabel1.utilityLabel.switchoffSummary7)}
              <span style={{ whiteSpace: "nowrap" }}>
              <a
            href={
              props.utilityLabel1.utilityLabel.switchoffPhoneHref
            }
            id="IVRPhone"
            onClick={(e) =>
              props?.trackEvents("IVRSorry", "IVRPhone", props.sessionID)
            }
            className="underlined"
            title={"Call "+props.propIvrPhone}
            aria-label={"Call "+props.propIvrPhone}
          >
            <u>{props.propIvrPhone}</u>
          </a>
          .
        </span>
            </p>
      </div>
    </div>
);
}
export default UmbrellaSorryComponent;
