/* eslint-disable no-unused-vars */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

import { Router, Switch, Route } from 'react-router-dom'
import SorryPage from './components/sorry.component/sorry'
import ThankyouPage from './components/thankyou.component/thankyou';
import history from './history';

const Routing = () => {
  return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" render={(props) => <App {...props} />} />
          <Route exact path="/?language=es&useraction=timeout*" render={(props) => <App {...props} />} />
          <Route exact path="/?language=en&useraction=timeout*" render={(props) => <App {...props} />} />
          <Route exact path="/?language=es&entrypoint=QR&useraction=timeout*" render={(props) => <App {...props} />} />
          <Route exact path="/?language=en&entryPoint=QR&useraction=timeout*" render={(props) => <App {...props} />} />
          <Route exact path="/?language=es&entrypoint=URL&useraction=timeout*" render={(props) => <App {...props} />} />
          <Route exact path="/?language=en&entryPoint=URL&useraction=timeout*" render={(props) => <App {...props} />} />
          <Route exact path="/?partner=gpcc" render={(props) => <App {...props} />} />

          <Route exact path="/?page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/?language=en&page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/?language=es&page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/?language=en&entrypoint=URL&page=sorry*" render={(props) => <SorryPage {...props} />} />

          <Route exact path="/?language=es&entrypoint=QR&page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/?language=en&entrypoint=QR&page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/?language=es&entrypoint=URL&page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/?language=en&entrypoint=URL&page=sorry*" render={(props) => <SorryPage {...props} />} />
          <Route path="/?language=es&entrypoint=QR&page=thankyou*" render={(props) => <ThankyouPage {...props} />} />
          <Route path="/?language=en&entrypoint=QR&page=thankyou*" render={(props) => <ThankyouPage {...props} />} />
          <Route path="/?language=es&entrypoint=URL&page=thankyou*" render={(props) => <ThankyouPage {...props} />} />
          <Route path="/?language=en&entrypoint=URL&page=thankyou*" render={(props) => <ThankyouPage {...props} />} />
          <Route exact path="/script" render={(props) => <SorryPage {...props} />} />
          <Route exact path="/static" render={(props) => <SorryPage {...props} />} />
        </Switch>
      </Router>

  )
}

ReactDOM.render(
  <Routing />,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
