import React from 'react';
import Modal from 'react-bootstrap/Modal';
import * as utility from "../../../utilities/utils";

export const IdleTimeOutModal = ({showModal, handleClose, remainingTime, language}) => {
if(showModal)
{
 utility.getTitleSessiontimeout();
}

    let utilityLabel1 = language === "es" ? require("../../resources/resources.es") : require("../../resources/resources.en")
    return (
        <Modal show={showModal} onHide={handleClose}
        centered={utility.getPartner() === 'gpcc' || utility.getPartner() === 'rubicon' || utility.getPartner() === 'onekey' || utility.getPartner() === 'onekeyplus'}
        aria-labelledby="modal-session-title">
            <Modal.Header >
            <Modal.Title  id="modal-session-title">{utilityLabel1.utilityLabel.idleModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="idle-time-out-body">{utilityLabel1.utilityLabel.idleModalMsg1}
            {remainingTime}{utilityLabel1.utilityLabel.idleModalMsg2}</Modal.Body>
            <Modal.Footer className="modal-footer text-center idle-timeout" style={{"justifyContent":"center"}}>
            <button aria-label="btnContinue"
            className={utility.getPartner() === 'gpcc' || utility.getPartner() === 'rubicon'|| utility.getPartner() === 'onekey' || utility.getPartner() === 'onekeyplus'
            ? "btn btn-block-0 px-5 btn-primary continueBtn" : "btn btn-sm btn-block-0 px-5 btn-primary continueBtn"}
             onClick={handleClose} data-dismiss="modal">{utilityLabel1.utilityLabel.continueBtn}</button>
            </Modal.Footer>
        </Modal>
    )
}