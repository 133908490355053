import React, { Component } from "react";


class RubiconHeader extends Component {
  constructor(props) {
    super(props);
  }

  handleError(e) {
    e.target.onerror = null;
    e.target.src =
      require("../../../global/img/wf/masthead-wf-logo-211x22.png");
  }

  render() {
    return (
        <banner className="header-wrapper">
          <a href="#eca-confirm-title" className="skip-to-main-content-link">Skip to main content</a>
          <div className="container-masthead bg-red border-0 border-md-bottom-4 border-yellow" id="rubiconHeader">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 text-center text-md-left" id="textElement">
                <span className="d-block wf-logo" id="imgLogo">
                  <img
                      id="imgRubiconLogo"
                      src={require("../../../global/img/wf/masthead-wf-logo-879x92.svg").default}
                      alt="Wells Fargo"
                      onError={this.handleError}
                      className="img-fluid"
                  />
                </span>
                </div>
              </div>
            </div>
          </div>
        </banner>
    );
  }
}

export default RubiconHeader;

