import React, { useState, useEffect } from "react";
import OLBConfirmLandingComponent from "./components/landing.component/olbConfirm.Landing.Component";
import ShortLandingPage from "./components/landing.component/short.landing.component";
import * as utility from "./utilities/utils";
import * as dataService from "./services/data.service";
import history from "./history";
import { NuDataComponent } from "./components/nuData.component/nuData";
import FusionLanding from "./components/fusion-component/fusion-landing";
import Header from "./components/shared/controls/header.js";
import Footer from "./components/shared/controls/footer.js";
import AttuneConfirmLandingComponent from "./components/landing.component/attuneConfirm.Landing.Component";
import { ServiceApi } from "./environment";

function MainContent(props) {
  const [switchVal, setswitchVal] = useState("");
  const [switchPartner, setswitchPartner] = useState("");
  const [ivrPhone, setivrPhone] = useState("");
  const [buttonClickMode, setButtonClickMode] = useState(false);
  const [choiceConvModal, setchoiceConvModal] = useState("");
  const nudata = new NuDataComponent();

  let params = utility.getParams();

  const getUnknownSorry = () => {
    let prodType = params.partner;
    if (prodType === "rubicon") {
      prodType = "gpcc";
    } else if (params.lob === "business-cards") {
      prodType = "rubicon";
    }

    history.push({
      pathname: utility.getURLInfo(),
      detail: {
        language: params.language,
        type: "UNKNOWN",
        entryPoint: params.entryPoint,
        productType: prodType,
        eventID:params.eventVal,
        ivrPhoneNumber:ivrPhone

      },
    });
      }

  const getSwitchInfo = (eventID, sessionID) => {
    let switchData;
    dataService.switchInfo(eventID, utility.getPartner(), sessionID).then(switchRes => {

      if (switchRes !== null && switchRes !== undefined) {
        switchData = switchRes.data;
        setswitchVal(switchData);
        if (utility.getPartner() === "ChoiceConv" && switchData.switchConfig === "OFF") {
          setchoiceConvModal("true");
          setswitchPartner("ON");
        } else if(!(utility.getPartner()==='gpcc')
          && switchData.switchConfig === "OFF") {
          setivrPhone(switchData.ivrPhoneNumber);
          setswitchPartner("OFF");
          setchoiceConvModal("false");
        } else {
          setswitchPartner("ON");
          setchoiceConvModal("false");
          setivrPhone(switchData.ivrPhoneNumber);
        }
      }
      nudata.GetNuData(sessionID, eventID);
    }).catch(err => {
      setswitchPartner("ON");
      setivrPhone("1-866-537-8424");
    });
  };


  const getEnvironmentVaribleInfo = (eventID, sessionID) => {
    dataService.environmentVaribleInfo(utility.getQString(), utility.getFlowInd(), utility.getPartner(), eventID,
      utility.getUserAction(), sessionID).then(envRes => {
      if (envRes !== null && envRes !== undefined) {
        ServiceApi.XAPIKEY = envRes.data.xapiKey;
        ServiceApi.PUBLICIP = envRes.data.publicIP;
        ServiceApi.XFORWARDEDFOR = envRes.data.forwardedFor;
        ServiceApi.NuData.JENNYURL = envRes.data.nudataJenny;
        ServiceApi.NuData.ATADUNURL = envRes.data.nudataAtadun;
        ServiceApi.AdrumData.ADRUMKEY = envRes.data.adrumKey;
        ServiceApi.AdrumData.BEACONURLHTTPS = envRes.data.beaconUrlHTTPS;
        ServiceApi.AdrumData.XDENABLED = envRes.data.xenabled;
        ServiceApi.OLB_SIGN_ON_ENG_URL = envRes.data.olbSignOnEngUrl;
        ServiceApi.OLB_ENROLL_ENG_URL = envRes.data.olbEnrollEngUrl;
        ServiceApi.OLB_SIGN_ON_SPN_URL = envRes.data.olbSignOnSpnUrl;
        ServiceApi.OLB_ENROLL_SPN_URL = envRes.data.olbEnrollSpnUrl;
        ServiceApi.SIGN_ON_ACCT_ENG_URL = envRes.data.signOnAcctEngUrl;
        ServiceApi.ENROLL_BANK_ENG_URL = envRes.data.enrollBankEngUrl;
        ServiceApi.SIGN_ON_ACCT_SPN_URL = envRes.data.signOnAcctSpnUrl;
        ServiceApi.ENROLL_BANK_SPN_URL = envRes.data.enrollBankSpnUrl;
        ServiceApi.ENROLLMENT_ENG_URL = envRes.data.enrollmentEngUrl;
        ServiceApi.ENROLLMENT_SPN_URL = envRes.data.enrollmentSpnUrl;
        ServiceApi.FOOTER_ENG_URL = envRes.data.footerEngUrl;
        ServiceApi.FOOTER_SPN_URL = envRes.data.footerSpnUrl;

        getSwitchInfo(eventID, sessionID);
      }
    }).catch(err => {
      getUnknownSorry();
    });
  };


  useEffect(() => {
    getEnvironmentVaribleInfo(props.eventID, props.sessionID);

  }, []);

  const handleOnButtonClick = (e) => {
    e.preventDefault();
    setButtonClickMode(true);
    let page = "OLB";
    let event = "ConfirmWithFewDetails";

    if (utility.getFlowInd() === "Q") {
      page = page.toUpperCase() + " QR";
    }

    utility.trackEventsForNoredirect(page, event, props.sessionID)
}


  const getContentOnOff = () => {

    if(switchPartner === 'OFF'&& !(utility.getPartner() === 'gpcc') ){
      getUnknownSorry()
      return<></>
    }

    if (utility.getPartner() === 'attune'){
      return <AttuneConfirmLandingComponent sessionID={props.sessionID}/>
    }
    else if(!buttonClickMode && (utility.getPartner() === 'gpcc'||utility.getPartner() ==='onekey'||utility.getPartner() ==='onekeyplus') && switchVal.switchOLBConfig === 'ON'){
      return <OLBConfirmLandingComponent prodType={utility.getPartner()}
       handleOnButtonClick={handleOnButtonClick} sessionID={props.sessionID}/>
    }
    else if( utility.getPartner() === 'bilt' || utility.getPartner() === 'choice' || utility.getPartner() === 'ChoiceConv'){
      return (props.eventID !== 0 ) && <div className="d-flex flex-column"><NuDataComponent sessionID={props.sessionID} />
          <ShortLandingPage sessionID={props.sessionID} switchVal={switchVal} switchPartner={switchPartner}
                            eventID={props.eventID} choiceConvModal={choiceConvModal} ivrPhone={ivrPhone} {...props} />
      </div>
    }
    else{
      return (props.eventID !== 0 ) && <div className="d-flex flex-column"><NuDataComponent sessionID={props.sessionID} />
          <FusionLanding sessionID={props.sessionID} switchVal={switchVal} switchPartner={switchPartner}
                         eventID={props.eventID} ivrPhone={ivrPhone} {...props} />
      </div>
    }
}

  return (
    <>
      <div className="d-flex flex-column dce-bgpage h-100 pace-done">
        <Header />
        {
        (   switchPartner !== ''  )  &&
          getContentOnOff()
        }
        <Footer />
      </div>
    </>
  );
}

export default MainContent;
