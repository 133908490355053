import React from "react";
import IdleTimer from "react-idle-timer";
import moment from "moment";
import history from "./history";
import { IdleTimeOutModal } from "./components/shared/controls/idleTimerModal.component";
import "bootstrap/dist/css/bootstrap.min.css";
import { getEntryPoint } from "./utilities/utils";
import { trackEvent } from "./services/data.service";
import * as utils from "./utilities/utils";

class SessionTimeoutComponent extends React.Component {
  
    constructor(props) {
        super(props);

        this.state = {
            timeout: 1000 * 60 * 10,
            idleShowModal: false,
            isTimedOut: false,
            displayWarningMsg: this.props.displayWarningMsg,
            sessionTimeoutDisplay: this.props.sessionTimeoutDisplay,
            remainingTime: "",
            pageLocation: "",
            eventID: utils.getUniqueID()

        };
        this.idleTimer = null;
        this._onAction = this._onAction.bind(this);
        this._onActive = this._onActive.bind(this);
        this._onIdle = this._onIdle.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    _onAction(e) {
        if (
            (e.type === "keydown" && e.key !== "Alt") ||
            e.type === "mousedown" ||
            e.type !== "visibilitychange" ||
            e.type === "click"
        ) {
            this.setState({ isTimedOut: false });
        }
    }

    _onActive(e) {
        this.setState({ isTimedOut: false });
    }

    _onIdle(e) {
        const isTimedOut = this.state.isTimedOut;
        const { handleCancelModalClose } = this.props;
        this.setState({ pageLocation: this.props.pageLocation });
        this.setState({});
        if (isTimedOut) {
            this.setState({ idleShowModal: false, displayWarningMsg: true }, () => {
                if (this.state.pageLocation === "landingPage") {
                    this.props.callbackParent(this.state);
                    history.push({
                        pathname: "/",
                        data: {
                            displayWarningMsg: this.state.displayWarningMsg,
                            sessionTimeoutDisplay: this.props.sessionTimeoutDisplay,
                            language: this.props.language
                        }
                    });
                }
            });
        } else {
            if (this.props.sessionTimeoutDisplay) this.setState({ idleShowModal: true, displayWarningMsg: false }, () => {
                if (handleCancelModalClose)
                    handleCancelModalClose();
            });
            this.remainingTimeout();
            this.setState({ isTimedOut: true });
        }
    }

    handleClose() {
        this.setState({
            idleShowModal: false,
            displayWarningMsg: false,
            isTimedOut: false
        }, this.props.modelClose());
    }

    remainingTimeout() {
        var cDate = new Date();
        var eDate = new Date(cDate.getTime() + 1000 * 60 * 5);
        var sessDate = moment(eDate).format("h:mm A");
        this.setState(
            {
                remainingTime: sessDate,
                displayWarningMsg: false
            },
            () => {
                setTimeout(this.handleSessionTimeout, 300000);
            }
        );
    }

    handleSessionTimeout = () => {
        if (this.state.idleShowModal) {
            this.navigateLandingPage();
        }
    };

    navigateLandingPage = () => {
        this.setState(
            {
                idleShowModal: false,
                displayWarningMsg: true,
                lang: this.props.language === "es" ? this.props.language : "en",
                entryPoint: getEntryPoint()
            },
            () => {

                trackEvent("Landing", "Timeout", this.props.sessionID).then({});

                let path = "";
                if (utils.getPartner() === "rubicon" && this.state.entryPoint === "") {
                    path = "/?language=" + this.state.lang + "&useraction=timeout" + "&lob=business-cards";
                } else if (utils.getPartner() === "rubicon") {
                    path = "/?language=" + this.state.lang + "&entrypoint=" + this.state.entryPoint + "&useraction=timeout" + "&lob=business-cards";
                } else if (this.state.entryPoint === "") {
                    path = "/?language=" + this.state.lang + "&useraction=timeout" + "&partner=" + utils.getPartner();
                } else {
                    path = "/?language=" + this.state.lang + "&entrypoint=" + this.state.entryPoint + "&useraction=timeout" + "&partner=" + utils.getPartner();
                }

                if (this.state.pageLocation === "landingPage") {
                    this.props.callbackParent(this.state);
                    history.push({
                        pathname: path,
                        data: {
                            displayWarningMsg: this.state.displayWarningMsg,
                            language: this.state.lang
                        }
                    });
                }
            }
        );
    };

    render() {
        return (
            <>
                <IdleTimer
                    ref={(ref) => {
                        this.idleTimer = ref;
                    }}
                    element={document}
                    onActive={this._onActive}
                    onIdle={this._onIdle}
                    onAction={this._onAction}
                    debounce={250}
                    timeout={this.state.timeout}
                />
                <IdleTimeOutModal
                    showModal={this.props.sessionTimeoutDisplay ? this.state.idleShowModal : this.props.sessionTimeoutDisplay}
                    handleClose={this.handleClose}
                    remainingTime={this.state.remainingTime}
                    language={this.props.language}
                />
            </>
        );
    }

}

export default SessionTimeoutComponent;