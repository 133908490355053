import React from "react";
import * as utility from "../../../utilities/utils";
import { ServiceApi } from "../../../environment";
import parse from 'html-react-parser';

function FusionFooter(props) {
  let prodType = utility.getPartner();
  let utilityLabel1 = utility.findUtilityLabelFromProductForFooter(prodType, utility.getQString());

  return (
    <>
      <ul className="mastfoot-ada-links">
        <li> <a href={parse(utilityLabel1.utilityLabel.footer1Href)}
          title={parse(utilityLabel1.utilityLabel.footer1Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer1Title)}>
          {parse(utilityLabel1.utilityLabel.footer1Title)}</a></li>
        <li>
          <a href={utility.getQString() === "en" ? ServiceApi.FOOTER_ENG_URL : ServiceApi.FOOTER_SPN_URL}
            title={parse(utilityLabel1.utilityLabel.footer14Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer14Title)}>
            {parse(utilityLabel1.utilityLabel.footer14Title)}</a>
        </li>
        <li>
          <a  href={parse(utilityLabel1.utilityLabel.footer2Href)}
            title={parse(utilityLabel1.utilityLabel.footer2Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer2Title)}>
            {parse(utilityLabel1.utilityLabel.footer2Title)}</a>
        </li>
        <li>
          <a href={parse(utilityLabel1.utilityLabel.footer3Href)}
            title={parse(utilityLabel1.utilityLabel.footer3Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer3Title)}>
            {parse(utilityLabel1.utilityLabel.footer3Title)}</a>
        </li>
        <li>
          <a href={parse(utilityLabel1.utilityLabel.footer5Href)}
            title={parse(utilityLabel1.utilityLabel.footer5Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer5Title)}>
            {parse(utilityLabel1.utilityLabel.footer5Title)}</a>
        </li>
        <li>
          <a href={parse(utilityLabel1.utilityLabel.footer6Href)}
            title={parse(utilityLabel1.utilityLabel.footer6Title)} tabIndex="-1"
            aria-label={parse(utilityLabel1.utilityLabel.footer6Title)}>{parse(utilityLabel1.utilityLabel.footer6Title)}</a>
        </li>
         <li>
          <a href={parse(utilityLabel1.utilityLabel.footer7Href)}
            title={parse(utilityLabel1.utilityLabel.footer7Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer7Title)}>
            {parse(utilityLabel1.utilityLabel.footer7Title)}</a>
        </li>

         {props.prodType === "rubicon"  ? "" :<li>
          <a href={parse(utilityLabel1.utilityLabel.footer8Href)}
            title={parse(utilityLabel1.utilityLabel.footer8Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer8Title)}>
            {parse(utilityLabel1.utilityLabel.footer8Title)}</a>
        </li> }
        <li>
          <a href={parse(utilityLabel1.utilityLabel.footer9Href)}
            title={parse(utilityLabel1.utilityLabel.footer9Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer9Title)}>
            {parse(utilityLabel1.utilityLabel.footer9Title)}</a></li>
        <li ><a href={parse(utilityLabel1.utilityLabel.footer10Href)} title={parse(utilityLabel1.utilityLabel.footer10Title)} tabIndex="-1" aria-label={parse(utilityLabel1.utilityLabel.footer10Title)}>
          {parse(utilityLabel1.utilityLabel.footer10Title)}</a></li></ul>


    </>
  );

}

export default FusionFooter;