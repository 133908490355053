import React, { useEffect }  from 'react';
import parse from 'html-react-parser';

function CustomerServiceComponent(props) {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <div className="row pagetitle-container">
                <div className="col-12 text-center pb-5 pb-md-4">
                    <h1 className="eca-terminate-title text-center text-divider-yellow pb-2 pb-md-3 pt-0 pt-sm-1" id="eca-confirm-title">
                        {parse(props.utilityLabel1.utilityLabel.customerServiceTitle)}</h1>
                </div>
            </div>

            <div className="row eca-container">
                <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
                    <a name="eca-page-top"></a>
                    <div className="row m-0 eca-terminate-summary-container">
                        <div className="col-12 mt-md-3">
                            <p className="eca-exit-summary1">{parse(props.utilityLabel1.utilityLabel.customerServiceSummary1)}</p>
                            <p className="eca-exit-summary2">{parse(props.utilityLabel1.utilityLabel.customerServiceSummary2)}</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default CustomerServiceComponent
